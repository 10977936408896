import { useEffect } from 'react';

import { ping } from '@/utilities/api';
import { redirectWithFlash } from '@/utilities/flash';
import { getWelcomePath } from '@/utilities/paths';

export function useRedirectAuthenticatedUser() {
  useEffect(() => {
    (async () => {
      try {
        const { isLoggedIn } = await ping();
        if (isLoggedIn) {
          redirectWithFlash(getWelcomePath(), 'Welcome back!', 'success');
        }
      } catch (e) {
        // do nothing, they are most likely on a guest route and can continue even with an error to ping
      }
    })();
  }, []);
}
