import classNames from 'classnames';
import { Component, createElement, ReactNode, useEffect, useId } from 'react';
import tippy, { followCursor } from 'tippy.js';

import 'tippy.js/themes/light-border.css';
import 'tippy.js/animations/shift-away.css';
import style from './text.module.css';

export interface IProps {
  children?: string | ReactNode;
  className?: string;
  element?: string;
  lineHeight?: number;
  brand?: boolean;
  noStyle?: boolean;
  title?: string;
}

// [f k=abc f=xyz]
export const regex = {
  furigana: /\[f k=(.+?) f=(.+?)\]/gi,
};

export function parseContent(content: string = ''): string {
  content = content.replace(regex.furigana, (_o, k, f) => {
    return `<span class="ruby">${k}<span class="rt">${f}</span></span>`;
  });

  // Moving away from .spacer, instead being handled as traditional markdown. But for now, we need to filter out empty text blocks
  // for legacy lessons in particular
  // can't just hide it because of nth-child selectors
  content = content.replaceAll(`<p class="spacer">&nbsp;</p>`, '');

  return content;
}

// For document title etc
export function withoutFurigana(content: string = '') {
  return content.replace(regex.furigana, (_o, k) => {
    return k;
  });
}

export function TextV2(props: { text: string }) {
  const { text } = props;
  const id = useId();

  const __html = text.replace(regex.furigana, (_o, k, f) => {
    return `<span data-tippy-id="${id}" data-tippy-content="${f}">${k}</span>`;
  });

  useEffect(() => {
    const instances = tippy(`[data-tippy-id="${id}"]`, {
      theme: 'light-border',
      animation: 'shift-away',
      followCursor: 'horizontal',
      plugins: [followCursor],
    });

    return () => {
      instances.forEach((instance) => {
        instance.destroy();
      });
    };
  }, [text, id]);

  return <div className={style.root} dangerouslySetInnerHTML={{ __html }} />;
}

export class Text extends Component<IProps> {
  static defaultProps: IProps = {
    brand: false,
    noStyle: false,
    element: 'div',
    children: '',
  };

  constructor(props: IProps) {
    super(props);
    this.state = { content: null };
  }

  shouldComponentUpdate(nextProps: IProps) {
    if (typeof nextProps.children !== 'string') {
      return true;
    }

    return nextProps.children !== this.props.children;
  }

  render() {
    const { className, children, element, lineHeight, noStyle, title, brand } = this.props;
    const props: any = {
      className: classNames({ [style.root]: !noStyle, [style.brand]: brand }, className),
      style: { lineHeight },
    };

    if (title) {
      props.title = title;
    }

    if (typeof children === 'string') {
      const __html = parseContent(children);

      // If there's no content, don't render anything, usually only occurs when removing empty spacers (see parse above)
      if (__html === '') {
        return null;
      }

      // @ts-ignore
      return createElement(element, { ...props, dangerouslySetInnerHTML: { __html } });
    } else {
      // @ts-ignore
      return createElement(element, props, children);
    }
  }
}
