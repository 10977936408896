import { ReactNode } from 'react';
import classNames from 'classnames';

import { Breadcrumb } from '@/components/breadcrumb/breadcrumb';
import { Text } from '@/components/text/text';
import { useTitle } from '@/hooks/use-title';

import style from './hero-card.module.css';

type HeroCardProps = {
  breadcrumbs?: Array<{ href?: string; label: string; onClick?: () => void } | null>;
  byline?: string;
  children: ReactNode;
  size: 'small' | 'medium' | 'wide' | 'extraWide';
  // Used for account settings where the page slides over the top
  fixedHeight?: boolean;
  title: string;
};

export function HeroCard(props: HeroCardProps) {
  const { breadcrumbs = [], byline, children, size, title, fixedHeight = false } = props;

  useTitle(title);

  return (
    <div className={classNames(style.root, style[size], { [style.fixedHeight]: fixedHeight })} id="HeroLayout__content">
      <div className={style.header}>
        <div className={style.titleWrapper}>
          <Text element="h1" className={classNames(style.title, 'global--invert')}>
            {title}
          </Text>
          {byline && <div className={style.byline}>{byline}</div>}
        </div>
      </div>

      {breadcrumbs.length > 0 && (
        <div className={style.breadcrumbWrapper}>
          <Breadcrumb items={breadcrumbs} />
        </div>
      )}

      <div className={style.content}>
        <div className={style.children}>{children}</div>
      </div>
    </div>
  );
}
