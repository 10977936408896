import classNames from 'classnames';
import { useState } from 'react';

import cn from './image-loader.module.css';

interface ImageLoaderProps {
  className?: string;
  src: string;
  credit?: string;
  creditLink?: string;
  size?: 'contain' | 'cover';
}

// Contain is essentially "unset" and doesn't try to set any particular height. Good for illustraiotns

export default function ImageLoader(props: ImageLoaderProps) {
  const { src, credit, creditLink, size = 'contain' } = props;
  const [loading, setLoading] = useState(true);
  const isCover = size === 'cover';

  return (
    <div className={classNames(cn.root, { [cn.isCover]: isCover })}>
      <div className={cn.wrapper}>
        <img className={cn.image} src={src} onLoad={() => setLoading(false)} alt={credit} />
        {credit && (
          <a target="_blank" className={cn.credit} href={creditLink} rel="noreferrer">
            {credit}
          </a>
        )}
      </div>
    </div>
  );
}
