import { ReactNode, useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

import Times from '@/icons/fa/solid/times';

import styles from './modal.module.css';
import { Button } from '../button/button';
import { ActionBar } from '../action-bar/action-bar';
import { useLocation } from 'react-router-dom';

type Props = {
  shortcut?: string;
  children?: ReactNode;
  trigger?: ReactNode;
  confirmLabel?: string;
  heading: string;
  onConfirm?: () => Promise<void>;
  confirmCategory?: 'primary' | 'destructive';
};

export function Modal(props: Props) {
  const { shortcut, children, confirmLabel, onConfirm, trigger, heading, confirmCategory = 'primary' } = props;
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm();
    }
    setOpen(false);
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === shortcut && !open) {
        e.preventDefault();
        setOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [open, shortcut]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay}>
          <Dialog.Content className={styles.content}>
            {/* HEADER */}
            <div className={styles.header}>
              <Dialog.Title className={styles.title}>{heading}</Dialog.Title>

              <Dialog.Close asChild>
                <button className={styles.close} aria-label="Close">
                  <Times className={styles.icon} />
                </button>
              </Dialog.Close>
            </div>

            <div className={styles.contentInner}>{children}</div>

            {/* FOOTER */}
            {confirmLabel && (
              <div className={styles.footer}>
                <ActionBar>
                  <Dialog.Close asChild>
                    <Button category="secondary">Cancel</Button>
                  </Dialog.Close>
                  <Button category={confirmCategory} onClick={handleConfirm}>
                    {confirmLabel}
                  </Button>
                </ActionBar>
              </div>
            )}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
