import classNames from 'classnames';

import CheckCircle from '@/icons/fa/solid/check-circle';
import TimesCircle from '@/icons/fa/solid/times-circle';

export { Highlight } from './components/highlight/highlight';
import { parseContent } from '../text/text';
import style from './feedback.module.css';

interface IProps {
  message: string;
  type: 'correct' | 'acceptable' | 'incorrect';
  small?: boolean;
  animate?: boolean;
  // unsafe - from user input, like results
  unsafe?: boolean;
}

const icons = {
  correct: CheckCircle,
  acceptable: CheckCircle,
  incorrect: TimesCircle,
};

export function Feedback(props: IProps) {
  const { animate = true, message, small = false, type, unsafe = false } = props;
  const Icon = icons[type];

  return (
    <div
      className={classNames(style.root, style[type], { [style.animate]: animate, [style.small]: small })}
      data-testid="feedback"
      data-type={type}
    >
      <div className={style.iconWrapper}>
        <span className={style.iconAnimation}>
          <Icon className={style.icon} />
        </span>
      </div>
      <div className={style.textWrapper}>
        {unsafe ? (
          <div className={style.text}>{message}</div>
        ) : (
          <div className={style.text} dangerouslySetInnerHTML={{ __html: parseContent(message) }} />
        )}
      </div>
    </div>
  );
}
