import { ReactNode } from 'react';

import { Check } from '@/components/check/check';

type AnswerProps = {
  active: boolean;
  correct: boolean;
  id: string;
  isComplete: boolean;
  subtext: ReactNode;
  text: ReactNode;
  handleAnswer: (id: string) => void;
  isSelected: (id: string) => boolean;
};

export function Answer(props: AnswerProps) {
  const { active, id, correct, subtext, text, isComplete, handleAnswer, isSelected } = props;
  return (
    <Check
      id={id}
      onClick={handleAnswer}
      selected={isSelected(id)}
      correct={correct}
      enabled={!isComplete && active}
      complete={isComplete}
      text={text}
      subtext={subtext}
    />
  );
}
