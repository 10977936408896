import { Link } from 'react-router-dom';

import style from './simple-link.module.css';

interface SimpleLinkProps {
  children: string;
  to: string;
  rel?: string;
  target?: string;
  testId?: string;
}

export function SimpleLink(props: SimpleLinkProps) {
  const { children, to, testId, target, rel } = props;

  return (
    <Link className={style.root} target={target} data-testid={testId} to={to} rel={rel}>
      {children}
    </Link>
  );
}
