import { Card } from '@/components/card/card';

import { mailAdmin } from '@/config';
import { Button } from '@/components/button/button';
import { getWelcomePath } from '@/utilities/paths';

import styles from './page404.module.css';

export default function Page404() {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <Card>
          <div className={styles.content}>
            <h1 className={styles.heading}>We couldn't find that page</h1>
            <p className={styles.body}>
              It looks like you've found a page that doesn't exist. If you think this is an error, please{' '}
              <a href={`mailto:${mailAdmin}`} className={styles.link}>
                contact us
              </a>
            </p>
            <Button href={getWelcomePath()}>Return home</Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
