import { ReactNode } from 'react';

import { Card } from '@/components/card/card';
import CheckCircle from '@/icons/fa/solid/check-circle';

import styles from './panel.module.css';

export type PanelProps = {
  children?: ReactNode;
  footer: ReactNode;
  heading: string;
  icon?: ReactNode;
  subHeading?: string | null;
};

const items = [
  '150+ of grammar lessons',
  '10+ additional stories with audio',
  'Verb conjugation practice',
  'Time practice',
  'Discord premium channels',
];

export function Panel(props: PanelProps) {
  const { icon, heading, subHeading, footer, children = null } = props;

  return (
    <div className={styles.root}>
      <Card>
        <div className={styles.headingWrapper}>
          <h2 className={styles.heading}>{heading}</h2>
          {icon}
        </div>

        {subHeading && <h3 className={styles.subHeading}>{subHeading}</h3>}

        <ul className={styles.list}>
          {items.map((item, i) => (
            <li className={styles.item} key={i}>
              <CheckCircle className={styles.icon} /> {item}
            </li>
          ))}
        </ul>

        {children && <div className={styles.button}>{children}</div>}

        <div className={styles.disclaimer}>Cancel anytime</div>
      </Card>
      <footer className={styles.footer}>{footer}</footer>
    </div>
  );
}
