import { Label } from '@/components/label/label';
import { Stack } from '@/components/stack/stack';
import { Feedback } from '@/components/feedback/feedback';

type ResultProps = {
  values: Array<{ isCorrect: boolean; text: string; key: string }>;
};

export function Results(props: ResultProps) {
  const { values } = props;

  return (
    <Stack>
      {values.length > 0 && (
        <>
          <Feedback
            unsafe
            key={values[0].key}
            type={values[0].isCorrect ? 'correct' : 'incorrect'}
            message={values[0].text}
          />
        </>
      )}
      {values.length > 1 && (
        <>
          <Label text="Previous attempts" />
          <Stack gap="space-2">
            {values.slice(1).map(({ isCorrect, text, key }) => (
              <Feedback unsafe key={key} animate={false} type={isCorrect ? 'correct' : 'incorrect'} message={text} />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
}
