import { Card } from '@/components/card/card';
import { GraphSimpleDonut } from '@/components/graph-simple-donut/graph-simple-donut';
import { HeroCard } from '@/components/hero-card/hero-card';
import { LessonCard } from '@/apps/app/features/lessons/lesson-card/lesson-card';
import { Loader } from '@/components/loader/loader';
import { useExpressLessons } from '@/utilities/api';

import styles from './lessons.module.css';

export default function Lessons() {
  const { isLoading, isSuccess, data = [] } = useExpressLessons();
  const isReady = !isLoading && isSuccess && data;

  return (
    <HeroCard title="Lessons" size="wide">
      {!isReady ? (
        <Loader isGlobal />
      ) : (
        <div className={styles.root}>
          <div className={styles.groups}>
            {data.map((level) => {
              const { heading, subHeading, lessons } = level;
              const percent = lessons.filter((l) => l.isRead).length / lessons.length;
              const label = `${Math.round(percent * 100)}%`;

              return (
                <Card padding="none" key={heading}>
                  <div className={styles.card}>
                    <div className={styles.header}>
                      <div className={styles.content}>
                        <h2 className={styles.heading}>{heading}</h2>
                        <p className={styles.body}>{subHeading}</p>
                      </div>
                      <div className={styles.bar}>
                        <GraphSimpleDonut percent={percent} label={label} />
                      </div>
                    </div>

                    <div>
                      <h3 className={styles.subHeading}>Lessons</h3>

                      {lessons.map((lesson, i) => (
                        <LessonCard
                          index={i + 1}
                          key={lesson.sysId}
                          interactionCount={lesson.interactionCount}
                          name={lesson.name}
                          isLocked={lesson.isLocked}
                          sysId={lesson.sysId}
                          isViewed={lesson.isRead}
                          isBookmarked={lesson.isBookmarked}
                        />
                      ))}
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </HeroCard>
  );
}
