import { Capacitor } from '@capacitor/core';

// Not using isMobile flag (below) because we don't want to tunnel when running in browser
// Everywhere else should use isMobile
export const API_URL = Capacitor.isNativePlatform()
  ? import.meta.env.VITE_API_TUNNEL_URL
  : import.meta.env.VITE_API_URL;
export const isFakeMobile = window.location.host === 'mobile.imawakatta.local:9002';
export const isMobile = Capacitor.isNativePlatform() || isFakeMobile;
export const mockRevenueCat = isFakeMobile;

export const APP_URL = import.meta.env.VITE_APP_URL;
export const LANDING_URL = import.meta.env.VITE_LANDING_URL;
export const paypalPublicKey = import.meta.env.VITE_PAYPAL_KEY;
export const revenueCat = import.meta.env.VITE_REVENUE_CAT;
export const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

export const version = '2.1.0';
export const mailFeedback = 'feedback@imawakatta.com';
export const mailIdeas = 'ideas@imawakatta.com';
export const mailAdmin = 'info@imawakatta.com';
export const twitchUrl = 'https://www.twitch.tv/miki_imawakatta';
export const termsUrl = 'https://imawakatta.com/terms';
export const privacyUrl = 'https://imawakatta.com/privacy';
export const appleCancelUrl = 'https://support.apple.com/en-us/HT202039';
export const appleEulaUrl = 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/';

export const isProduction = import.meta.env.PROD;

// prettier-ignore
export const english = [
  "a",  "i",  "u",  "e",  "o",
  "ka", "ki", "ku", "ke", "ko",
  "ga", "gi", "gu", "ge", "go",
  "sa", "shi","su", "se", "so",
  "za", "ji", "zu", "ze", "zo",
  "ta", "chi","tsu","te", "to",
  "da",             "de", "do",
  "na", "ni", "nu", "ne", "no",
  "ha", "hi", "fu", "he", "ho",
  "ba", "bi", "bu", "be", "bo",
  "pa", "pi", "pu", "pe", "po",
  "ma", "mi", "mu", "me", "mo",
  "ya",       "yu",       "yo",
  "ra", "ri", "ru", "re", "ro",
  "wa",                   
  "n",
];

// prettier-ignore
export const hiragana = [
  "あ", "い", "う", "え", "お",
  "か", "き", "く", "け", "こ",
  "が", "ぎ", "ぐ", "げ", "ご",
  "さ", "し", "す", "せ", "そ",
  "ざ", "じ", "ず", "ぜ", "ぞ",
  "た", "ち", "つ", "て", "と",
  "だ",             "で", "ど",
  "な", "に", "ぬ", "ね", "の",
  "は", "ひ", "ふ", "へ", "ほ",
  "ば", "び", "ぶ", "べ", "ぼ",
  "ぱ", "ぴ", "ぷ", "ぺ", "ぽ",
  "ま", "み", "む", "め", "も",
  "や",       "ゆ",      "よ",
  "ら", "り", "る", "れ" ,"ろ",
  "わ",                  
  "ん",
];

// prettier-ignore
export const katakana = [
  "ア", "イ", "ウ", "エ", "オ",
  "カ", "キ", "ク", "ケ", "コ",
  "ガ", "ギ", "グ", "ゲ", "ゴ",
  "サ", "シ", "ス", "セ", "ソ",
  "ザ", "ジ", "ズ", "ゼ", "ゾ",
  "タ", "チ", "ツ", "テ", "ト",
  "ダ",             "デ", "ド",
  "ナ", "ニ", "ヌ", "ネ", "ノ",
  "ハ", "ヒ", "フ", "ヘ", "ホ",
  "バ", "ビ", "ブ", "ベ", "ボ",
  "パ", "ピ", "プ", "ペ", "ポ",
  "マ", "ミ", "ム", "メ", "モ",
  "ヤ",       "ユ",      "ヨ",
  "ラ", "リ", "ル", "レ" ,"ロ",
  "ワ",                 
  "ン",
];

export const hiraganaTable = [
  ['あ', 'い', 'う', 'え', 'お'],
  ['a', 'i', 'u', 'e', 'o'],
  ['か', 'き', 'く', 'け', 'こ'],
  ['ka', 'ki', 'ku', 'ke', 'ko'],
  ['さ', 'し', 'す', 'せ', 'そ'],
  ['sa', 'shi', 'su', 'se', 'so'],
  ['た', 'ち', 'つ', 'て', 'と'],
  ['ta', 'chi', 'tsu', 'te', 'to'],
  ['な', 'に', 'ぬ', 'ね', 'の'],
  ['na', 'ni', 'nu', 'ne', 'no'],
  ['は', 'ひ', 'ふ', 'へ', 'ほ'],
  ['ha', 'hi', 'fu', 'he', 'ho'],
  ['ま', 'み', 'む', 'め', 'も'],
  ['ma', 'mi', 'mu', 'me', 'mo'],
  ['や', '', 'ゆ', '', 'よ'],
  ['ya', '', 'yu', '', 'yo'],
  ['ら', 'り', 'る', 'れ', 'ろ'],
  ['ra', 'ri', 'ru', 're', 'ro'],
  ['わ', '', '', '', 'を'],
  ['wa', '', '', '', 'o'],
  ['ん', '', '', '', ''],
  ['n', '', '', '', ''],
];

export const hiraganaTenTable = [
  ['が', 'ぎ', 'ぐ', 'げ', 'ご'],
  ['ga', 'gi', 'gu', 'ge', 'go'],
  ['ざ', 'じ', 'ず', 'ぜ', 'ぞ'],
  ['za', 'ji', 'zu', 'ze', 'zo'],
  ['だ', 'ぢ', 'づ', 'で', 'ど'],
  ['da', 'ji', 'zu', 'de', 'do'],
  ['ば', 'び', 'ぶ', 'べ', 'ぼ'],
  ['ba', 'bi', 'bu', 'be', 'bo'],
  ['ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ'],
  ['pa', 'pi', 'pu', 'pe', 'po'],
];

export const hiraganaYaTable = [
  ['きゃ', '', 'きゅ', '', 'きょ'],
  ['kya', '', 'kyu', '', 'kyo'],
  ['しゃ', '', 'しゅ', '', 'しょ'],
  ['sha', '', 'shu', '', 'sho'],
  ['ちゃ', '', 'ちゅ', '', 'ちょ'],
  ['cha', '', 'chu', '', 'cho'],
  ['にゃ', '', 'にゅ', '', 'にょ'],
  ['nya', '', 'nyu', '', 'nyo'],
  ['ひゃ', '', 'ひゅ', '', 'ひょ'],
  ['hya', '', 'hyu', '', 'hyo'],
  ['みゃ', '', 'みゅ', '', 'みょ'],
  ['mya', '', 'myu', '', 'myo'],
  ['りゃ', '', 'りゅ', '', 'りょ'],
  ['rya', '', 'ryu', '', 'ryo'],
  ['ぎゃ', '', 'ぎゅ', '', 'ぎょ'],
  ['gya', '', 'gyu', '', 'gyo'],
  ['じゃ', '', 'じゅ', '', 'じょ'],
  ['ja', '', 'ju', '', 'jo'],
  ['びゃ', '', 'びゅ', '', 'びょ'],
  ['bya', '', 'byu', '', 'byo'],
  ['ぴゃ', '', 'ぴゅ', '', 'ぴょ'],
  ['pya', '', 'pyu', '', 'pyo'],
];

export const katakanaTable = [
  ['ア', 'イ', 'ウ', 'エ', 'オ'],
  ['a', 'i', 'u', 'e', 'o'],
  ['カ', 'キ', 'ク', 'ケ', 'コ'],
  ['ka', 'ki', 'ku', 'ke', 'ko'],
  ['サ', 'シ', 'ス', 'セ', 'ソ'],
  ['sa', 'shi', 'su', 'se', 'so'],
  ['タ', 'チ', 'ツ', 'テ', 'ト'],
  ['ta', 'chi', 'tsu', 'te', 'to'],
  ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
  ['na', 'ni', 'nu', 'ne', 'no'],
  ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'],
  ['ha', 'hi', 'fu', 'he', 'ho'],
  ['マ', 'ミ', 'ム', 'メ', 'モ'],
  ['ma', 'mi', 'mu', 'me', 'mo'],
  ['ヤ', '', 'ユ', '', 'ヨ'],
  ['ya', '', 'yu', '', 'yo'],
  ['ラ', 'リ', 'ル', 'レ', 'ロ'],
  ['ra', 'ri', 'ru', 're', 'ro'],
  ['ワ', '', '', '', 'ヲ'],
  ['wa', '', '', '', 'o'],
  ['ン', '', '', '', ''],
  ['n', '', '', '', ''],
];

export const katakanaTenTable = [
  ['ガ', 'ギ', 'グ', 'ゲ', 'ゴ'],
  ['ga', 'gi', 'gu', 'ge', 'go'],
  ['ザ', 'ジ', 'ズ', 'ゼ', 'ゾ'],
  ['za', 'ji', 'zu', 'ze', 'zo'],
  ['ダ', 'ヂ', 'ヅ', 'デ', 'ド'],
  ['da', 'ji', 'zu', 'de', 'do'],
  ['バ', 'ビ', 'ブ', 'ベ', 'ボ'],
  ['ba', 'bi', 'bu', 'be', 'bo'],
  ['パ', 'ピ', 'プ', 'ペ', 'ポ'],
  ['pa', 'pi', 'pu', 'pe', 'po'],
];

export const katakanaYaTable = [
  ['キャ', '', 'キュ', '', 'キョ'],
  ['kya', '', 'kyu', '', 'kyo'],
  ['シャ', '', 'シュ', 'シェ', 'ショ'],
  ['sha', '', 'shu', 'she', 'sho'],
  ['チャ', '', 'チュ', 'チェ', ' チョ'],
  ['cha', '', 'chu', 'che', 'cho'],
  ['ニャ', '', 'ニュ', '', 'ニョ'],
  ['nya', '', 'nyu', '', 'nyo'],
  ['ヒャ', '', 'ヒュ', '', 'ヒョ'],
  ['hya', '', 'hyu', '', 'hyo'],
  ['ミャ', '', 'ミュ', '', 'ミョ'],
  ['mya', '', 'myu', '', 'myo'],
  ['リャ', '', 'リュ', '', 'リョ'],
  ['rya', '', 'ryu', '', 'ryo'],
  ['ギャ', '', 'ギュ', '', 'ギョ'],
  ['gya', '', 'gyu', '', 'gyo'],
  ['ジャ', '', 'ジュ', 'ジェ', 'ジョ'],
  ['ja', '', 'ju', 'je', 'jo'],
  ['ビャ', '', 'ビュ', '', 'ビョ'],
  ['bya', '', 'byu', '', 'byo'],
  ['ピャ', '', 'ピュ', '', 'ピョ'],
  ['pya', '', 'pyu', '', 'pyo'],
];

export const katakanaAdvancedTable = [
  ['', 'ウィ', '', 'ウェ', 'ウォ'],
  ['', 'wi', '', 'we', 'wo'],
  ['ツァ', 'ツィ', '', 'ツェ', 'ツォ'],
  ['tsa', 'tsi', '', 'tse', 'tso'],
  ['', 'ティ', 'トゥ', '', ''],
  ['', 'ti', 'tu', '', ''],
  ['ファ', 'フィ', 'フュ', 'フェ', 'フォ'],
  ['fa', 'fi', 'fyu', 'fe', 'fo'],
  ['', 'ディ', 'デュ', '', ''],
  ['', 'di', 'dyu', '', ''],
  ['ヴァ', 'ヴィ', 'ヴ', 'ヴェ', 'ヴォ'],
  ['va', 'vi', 'vu', 've', 'vo'],
];

// prettier-ignore
export const hours = [
  { number: 1, label: '一時', preferred: ['いちじ'], acceptable: [] },
  { number: 2, label: '二時', preferred: ['にじ'], acceptable: [] },
  { number: 3, label: '三時', preferred: ['さんじ'], acceptable: [] },
  { number: 4, label: '四時', preferred: ['よじ'], acceptable: [] },
  { number: 5, label: '五時', preferred: ['ごじ'], acceptable: [] },
  { number: 6, label: '六時', preferred: ['ろくじ'], acceptable: [] },
  { number: 7, label: '七時', preferred: ['しちじ'], acceptable: ['ななじ'] },
  { number: 8, label: '八時', preferred: ['はちじ'], acceptable: [] },
  { number: 9, label: '九時', preferred: ['くじ'], acceptable: [] },
  { number: 10, label: '十時', preferred: ['じゅうじ'], acceptable: [] },
  { number: 11, label: '十一時', preferred: ['じゅういちじ'], acceptable: [] },
  { number: 12, label: '十二時', preferred: ['じゅうにじ'], acceptable: [] },
]
// prettier-ignore
export const minutes = [
  { number: 0, kanji: '〇分', preferred: [''], acceptable: ['ゼロふん', 'れいふん'] },
  { number: 1, kanji: '一分', preferred: ['いっぷん'], acceptable: [] },
  { number: 2, kanji: '二分', preferred: ['にふん'], acceptable: [] },
  { number: 3, kanji: '三分', preferred: ['さんぷん'], acceptable: [] },
  { number: 4, kanji: '四分', preferred: ['よんぷん'], acceptable: [] },
  { number: 5, kanji: '五分', preferred: ['ごふん'], acceptable: [] },
  { number: 6, kanji: '六分', preferred: ['ろっぷん'], acceptable: [] },
  { number: 7, kanji: '七分', preferred: ['ななふん'], acceptable: [] },
  { number: 8, kanji: '八分', preferred: ['はっぷん', 'はちふん'], acceptable: [] },
  { number: 9, kanji: '九分', preferred: ['きゅうふん'], acceptable: [] },
  { number: 10, kanji: '十分', preferred: ['じゅっぷん'], acceptable: [] },
  { number: 11, kanji: '十一分', preferred: ['じゅういっぷん'], acceptable: [] },
  { number: 12, kanji: '十二分', preferred: ['じゅうにふん'], acceptable: [] },
  { number: 13, kanji: '十三分', preferred: ['じゅうさんぷん'], acceptable: [] },
  { number: 14, kanji: '十四分', preferred: ['じゅうよんぷん'], acceptable: [] },
  { number: 15, kanji: '十五分', preferred: ['じゅうごふん'], acceptable: [] },
  { number: 16, kanji: '十六分', preferred: ['じゅうろっぷん'], acceptable: [] },
  { number: 17, kanji: '十七分', preferred: ['じゅうななふん'], acceptable: [] },
  { number: 18, kanji: '十八分', preferred: ['じゅうはっぷん','じゅうはちふん'], acceptable: [] },
  { number: 19, kanji: '十九分', preferred: ['じゅうきゅうふん'], acceptable: [] },
  { number: 20, kanji: '二十分', preferred: ['にじゅっぷん'], acceptable: [] },
  { number: 21, kanji: '二十一分', preferred: ['にじゅういっぷん'], acceptable: [] },
  { number: 22, kanji: '二十二分', preferred: ['にじゅうにふん'], acceptable: [] },
  { number: 23, kanji: '二十三分', preferred: ['にじゅうさんぷん'], acceptable: [] },
  { number: 24, kanji: '二十四分', preferred: ['にじゅうよんぷん'], acceptable: [] },
  { number: 25, kanji: '二十五分', preferred: ['にじゅうごふん'], acceptable: [] },
  { number: 26, kanji: '二十六分', preferred: ['にじゅうろっぷん'], acceptable: [] },
  { number: 27, kanji: '二十七分', preferred: ['にじゅうななふん'], acceptable: [] },
  { number: 28, kanji: '二十八分', preferred: ['にじゅうはっぷん', 'にじゅうはちふん'], acceptable: [] },
  { number: 29, kanji: '二十九分', preferred: ['にじゅうきゅうふん'], acceptable: [] },
  { number: 30, kanji: '三十分', preferred: ['はん'], acceptable: ['さんじゅっぷん'] },
  { number: 31, kanji: '三十一分', preferred: ['さんじゅういっぷん'], acceptable: [] },
  { number: 32, kanji: '三十二分', preferred: ['さんじゅうにふん'], acceptable: [] },
  { number: 33, kanji: '三十三分', preferred: ['さんじゅうさんぷん'], acceptable: [] },
  { number: 34, kanji: '三十四分', preferred: ['さんじゅうよんぷん'], acceptable: [] },
  { number: 35, kanji: '三十五分', preferred: ['さんじゅうごふん'], acceptable: [] },
  { number: 36, kanji: '三十六分', preferred: ['さんじゅうろっぷん'], acceptable: [] },
  { number: 37, kanji: '三十七分', preferred: ['さんじゅうななふん'], acceptable: [] },
  { number: 38, kanji: '三十八分', preferred: ['さんじゅうはっぷん', 'さんじゅうはちふん'], acceptable: [] },
  { number: 39, kanji: '三十九分', preferred: ['さんじゅうきゅうふん'], acceptable: [] },
  { number: 40, kanji: '四十分', preferred: ['よんじゅっぷん'], acceptable: [] },
  { number: 41, kanji: '四十一分', preferred: ['よんじゅういっぷん'], acceptable: [] },
  { number: 42, kanji: '四十二分', preferred: ['よんじゅうにふん'], acceptable: [] },
  { number: 43, kanji: '四十三分', preferred: ['よんじゅうさんぷん'], acceptable: [] },
  { number: 44, kanji: '四十四分', preferred: ['よんじゅうよんぷん'], acceptable: [] },
  { number: 45, kanji: '四十五分', preferred: ['よんじゅうごふん'], acceptable: [] },
  { number: 46, kanji: '四十六分', preferred: ['よんじゅうろっぷん'], acceptable: [] },
  { number: 47, kanji: '四十七分', preferred: ['よんじゅうななふん'], acceptable: [] },
  { number: 48, kanji: '四十八分', preferred: ['よんじゅうはっぷん', 'よんじゅうはちふん'], acceptable: [] },
  { number: 49, kanji: '四十九分', preferred: ['よんじゅうきゅうふん'], acceptable: [] },
  { number: 50, kanji: '五十分', preferred: ['ごじゅっぷん'], acceptable: [] },
  { number: 51, kanji: '五十一分', preferred: ['ごじゅういっぷん'], acceptable: [] },
  { number: 52, kanji: '五十二分', preferred: ['ごじゅうにふん'], acceptable: [] },
  { number: 53, kanji: '五十三分', preferred: ['ごじゅうさんぷん'], acceptable: [] },
  { number: 54, kanji: '五十四分', preferred: ['ごじゅうよんぷん'], acceptable: [] },
  { number: 55, kanji: '五十五分', preferred: ['ごじゅうごふん'], acceptable: [] },
  { number: 56, kanji: '五十六分', preferred: ['ごじゅうろっぷん'], acceptable: [] },
  { number: 57, kanji: '五十七分', preferred: ['ごじゅうななふん'], acceptable: [] },
  { number: 58, kanji: '五十八分', preferred: ['ごじゅうはっぷん', 'ごじゅうはちふん'], acceptable: [] },
  { number: 59, kanji: '五十九分', preferred: ['ごじゅうきゅうふん'], acceptable: [] },
]
