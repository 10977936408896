import classNames from 'classnames';

import { Text } from '@/components/text/text';
import CheckSquare from '@/icons/fa/solid/check-square';
import MinusSquare from '@/icons/fa/solid/minus-square';
import Square from '@/icons/fa/regular/square';

import styles from './checkbox.module.css';

export type CheckboxValue = 'on' | 'partial' | 'off';

type CheckboxProps = {
  disabled?: boolean;
  label?: string;
  onChange?: (value: CheckboxValue) => void;
  testId?: string;
  value: CheckboxValue;
};

export function Checkbox(props: CheckboxProps) {
  const { label = '', value, onChange = () => {}, disabled = false, testId } = props;

  const onClick = () => {
    if (disabled) return;

    const newValue = value === 'off' || value === 'partial' ? 'on' : 'off';

    onChange(newValue);
  };

  return (
    <div
      className={classNames(styles.root, styles[value], { [styles.disabled]: disabled })}
      onClick={onClick}
      data-testid={testId}
    >
      <div className={styles.check}>
        <Square className={classNames(styles.icon, styles.iconOff)} />
        <MinusSquare className={classNames(styles.icon, styles.iconPartial)} />
        <CheckSquare className={classNames(styles.icon, styles.iconOn)} />
      </div>
      <Text noStyle className={styles.label}>
        {label}
      </Text>
    </div>
  );
}
