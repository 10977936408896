import { produce } from 'immer';
import { useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import * as Dialog from '@radix-ui/react-dialog';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { QuizResponse, useConjugation } from '@/utilities/api';
import { H2 } from '@/components/h2/h2';
import { HeroCard } from '@/components/hero-card/hero-card';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { Loader } from '@/components/loader/loader';
import { Modal } from '@/components/modal/modal';
import { recordResponse } from '@/utilities/api';
import { Results } from '@/components/results/results';
import { Select } from '@/components/select/select';
import { Stack } from '@/components/stack/stack';
import { Text } from '@/components/text/text';
import { useKanaValue } from '@/hooks/use-kana-value';
import Cog from '@/icons/fa/solid/cog';
import ArrowAltRight from '@/icons/fa/solid/arrow-alt-right';

import styles from './conjugation-practice.module.css';

type Answer = {
  id: string;
  question: string;
  answer: string;
  solution: string;
  correct: boolean;
};

type FormType = QuizResponse['config']['types'][0]['id'];

export default function ConjugationPractice() {
  const [fromId, setFromId] = useState<FormType | null>(null);
  const [toId, setToId] = useState<FormType | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState(0);
  const [answer, setAnswer] = useKanaValue('');
  const [answers, setAnswers] = useState<Array<Answer>>([]);

  const { data, isFetched } = useConjugation((quiz) => {
    setFromId(quiz.config.types[0].id);
    setToId(quiz.config.types[1].id);
  });

  const types = data?.quiz?.config.types ?? [];
  const from = types.find((t) => t.id === fromId);
  const to = types.find((t) => t.id === toId);

  const isLoading = !fromId || !toId || !from || !to || !isFetched;

  const resultsValues = answers.map(({ id, question, answer, solution, correct }) => ({
    key: id,
    isCorrect: correct,
    text: correct
      ? `Successfully converted 「${question}」 to 「${answer}」`
      : `Expected 「${question}」 to be 「${solution}」. You answered 「${answer}」`,
  }));

  const words = data?.quiz?.items ?? [];
  const word = words[current];

  const solution = toId ? word[toId] : '';
  const question = fromId ? word[fromId] : '';

  useEffect(() => {
    inputRef.current?.focus();
  }, [isLoading]);

  const onChange = (_value: string, e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheck();
    }
  };

  const onCheck = () => {
    const type = data?.config?.type ?? '';
    const sysId = data?.config?.sysId ?? '';
    const correct = answer === solution;
    const score = correct ? 5 : 1;

    recordResponse(type, sysId, score, {
      question,
      answer,
      solution,
      from: fromId,
      to: toId,
    });
    setAnswers(
      produce(answers, (draft) => {
        draft.unshift({ id: uniqueId(), correct, solution, answer, question });
      })
    );
    setAnswer('');
    setCurrent(current + 1);
    resultsRef.current?.scrollIntoView({ behavior: 'smooth' });
    inputRef.current?.focus();
  };

  return (
    <HeroCard title="Conjugation practice" breadcrumbs={[{ label: 'Practice', href: '/practice' }]} size="medium">
      <div className={styles.root}>
        {isLoading && <Loader isGlobal />}
        {!isLoading && (
          <Card>
            <Stack gap="space-9">
              <div>
                <Text>
                  <p>
                    "Conjugation" is the process of changing a verb from one “form” to another.
                    {/* If you’re not familar with conjugation, you should visit this lesson first. */}
                  </p>
                </Text>

                <Modal
                  heading="How to practice"
                  trigger={
                    <div>
                      <Button small category="link">
                        View instructions
                      </Button>
                    </div>
                  }
                >
                  <Stack>
                    <H2>How to practice</H2>
                    <Text>
                      <p>
                        <strong>1)</strong> In the first box a verb is displayed in <strong>{from.label}</strong>
                      </p>
                      <p>
                        <strong>2)</strong> In the field, type that verb in <strong>{to.label}</strong>
                      </p>
                      <p>
                        <strong>3)</strong> Click the “check” button
                      </p>
                      <p>
                        <strong>Tip:</strong> You can use the ENTER key to check your answer
                      </p>
                    </Text>
                  </Stack>
                </Modal>
              </div>

              <div className={styles.questionRow} ref={resultsRef}>
                <div className={styles.questionColumn}>
                  <div className={styles.question}>{word[fromId] ?? ''}</div>
                  <small>{from.label}</small>
                </div>
                <ArrowAltRight className={styles.arrow} />
                <div className={styles.questionColumn}>
                  <div className={styles.question}>?</div>
                  <small>{to.label}</small>
                </div>
              </div>

              <Label text={`Type ${word[fromId] ?? ''} in ${to.label}`}>
                <Input ref={inputRef} onChange={setAnswer} onKeyUp={onChange} value={answer} />
              </Label>

              <ActionBar variant="space-between">
                <Modal
                  heading="Form settings"
                  trigger={
                    <Button category="secondary" postIcon={Cog}>
                      Form settings
                    </Button>
                  }
                >
                  <Stack>
                    <H2>Update conjugation settings</H2>
                    <Text>
                      <p>You can change the forms you are practicing by updating the below settings:</p>
                    </Text>
                    <Stack variant="rowToColumn">
                      <div className={styles.modalColumn}>
                        <Label text="From form">
                          <Select
                            value={fromId}
                            onChange={(value) => setFromId(value as FormType)}
                            labelKey="label"
                            valueKey="id"
                            options={types}
                          />
                        </Label>
                      </div>
                      <div className={styles.modalColumn}>
                        <Label text="To form">
                          <Select
                            value={toId}
                            onChange={(value) => setToId(value as FormType)}
                            labelKey="label"
                            valueKey="id"
                            options={types}
                          />
                        </Label>
                      </div>
                    </Stack>
                    <ActionBar>
                      <Dialog.Close asChild>
                        <Button>Save and return to quiz</Button>
                      </Dialog.Close>
                    </ActionBar>
                  </Stack>
                </Modal>
                <Button type="submit" tabIndex={-1} onClick={onCheck}>
                  Check
                </Button>
              </ActionBar>
              {answers.length > 0 && (
                <>
                  <Results values={resultsValues} />
                </>
              )}
            </Stack>
          </Card>
        )}
      </div>
    </HeroCard>
  );
}
