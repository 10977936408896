export const getBillingPath = () => {
  return '/account/subscription';
};

export const getWelcomePath = () => {
  return `/`;
};

export const getLessonsPath = () => {
  return `/lessons`;
};

export const get404Path = () => {
  return `/404`;
};

export const getLessonPath = (lessonSysId: string) => {
  return `/lessons/${lessonSysId}`;
};

export const getStoriesPath = () => {
  return `/stories`;
};

export const getResourcesPath = () => {
  return '/resources';
};

export const getStoryPath = (storySysId: string) => {
  return `/stories/${storySysId}`;
};

export const getPracticePath = (quiz?: string) => {
  return `/practice` + (quiz ? `/${quiz}` : '');
};

export const getAccountPath = () => {
  return `/account/details`;
};

export const getMobileAccountPath = () => {
  return `/account`;
};

export const getDiscussPath = () => {
  return 'https://discord.gg/8hw73Zt6Az';
};

export const getLoginPath = () => {
  return `/login`;
};

export const getRegisterPath = () => {
  return `/register`;
};

export const getResetPath = () => {
  return `/password/reset`;
};

export const isGuestPath = (path: string) =>
  path === getLoginPath() || path === getRegisterPath() || path === getResetPath();

export const encodeBase64 = (data: string) => {
  return btoa(data);
};

export const decodeBase64 = (data: string) => {
  return atob(data);
};
