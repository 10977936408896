import { ReactNode } from 'react';

import style from './form-error.module.scss';

interface IProps {
  children?: ReactNode;
}

export function FormError(props: IProps) {
  const { children } = props;
  return <div className={style.root}>{children}</div>;
}
