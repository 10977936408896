import { ReactNode } from 'react';
import classNames from 'classnames';

import { Audio } from '@/components/audio/audio';
import { TextV2 } from '@/components/text/text';

import style from './sentence.module.css';

type SentenceProps = {
  audios?: { url: string }[];
  body?: string;
  children?: ReactNode;
  images?: { style: any }[];
  number?: string;
  layout?: 'horizontal' | 'vertical';
  title?: string;
};

export function Sentence(props: SentenceProps) {
  const {
    children = null,
    body,
    number,
    images = [],
    audios = [],
    layout = 'horizontal',
    title = 'Example sentence ',
  } = props;

  const classes = classNames(style.root, { [style.hasImage]: images.length > 0 }, style[layout]);

  return (
    <div className={classes}>
      <div className={style.container}>
        <div className={style.content}>
          <h2 className={style.heading}>
            {title}
            {number}
          </h2>
          {body ? <TextV2 text={body} /> : null}
          {children}
        </div>
        {images.length > 0 && (
          <div className={style.images}>
            {images.map((image, i) => (
              <div className={style.image} key={i} style={image.style} />
            ))}
          </div>
        )}
      </div>
      {audios.length > 0 && (
        <div className={style.audioContainer}>
          {audios.map((audio, i) => (
            <div className={style.audio} key={i}>
              <Audio url={audio.url} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
