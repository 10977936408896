import { ReactNode } from 'react';

import InfoIcon from '@/icons/fa/solid/info';
import { IconProps } from '@/icons/icon-props';

export { Key } from './components/key/key';
export { Highlight } from './components/highlight/highlight';
import style from './info.module.css';

type InfoProps = {
  children: ReactNode;
  hideIcon?: boolean;
  icon?: (props: IconProps) => JSX.Element;
};

export function Info(props: InfoProps) {
  const { children, hideIcon = false, icon: Icon = InfoIcon } = props;
  return (
    <div className={style.root}>
      {!hideIcon && (
        <div className={style.iconWrapper}>
          <Icon className={style.icon} />
        </div>
      )}
      <div className={style.text}>{children}</div>
    </div>
  );
}
