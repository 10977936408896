import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { AccountClose } from '@/components/account-close/account-close';
import { AccountContact } from '@/components/account-contact/account-contact';
import { AccountDetails } from '@/components/account-details/account-details';
import { AccountInvoices } from '@/components/account-invoices/account-invoices';
import { AccountSubscription } from '@/components/account-subscription/account-subscription';
import { ActionList } from '@/components/action-list/action-list';
import { HeroCard } from '@/components/hero-card/hero-card';
import { Loader } from '@/components/loader/loader';
import { logout, updateMe, useQueryMe, useQueryPaymentLinks } from '@/utilities/api';
import { useAuthenticatedLayoutContext } from '@/layouts/authenticated-layout/authenticated-layout';
import CommentSmile from '@/icons/fa/solid/comment-smile';
import LogoMark from '@/icons/logo-mark';
import Receipt from '@/icons/fa/solid/receipt';
import SignOut from '@/icons/fa/solid/sign-out';
import Trash from '@/icons/fa/solid/trash';
import UserCircle from '@/icons/fa/solid/user-circle';

import styles from './account.module.css';

export default function Account() {
  const [isSyncing, setIsSyncing] = useState<false | 'sync' | 'payment'>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { user } = useAuthenticatedLayoutContext();
  const meQuery = useQueryMe();

  const linksQuery = useQueryPaymentLinks();

  // Handle coming back from Stripe upgrade
  useEffect(() => {
    if (window.location.search === '?action=refresh') {
      setIsSyncing('sync');
      setTimeout(() => {
        window.location.replace(window.location.pathname);
      }, 3000);
    }
  }, []);

  const onSubmit = async (name: string) => {
    setIsSaving(true);
    try {
      await updateMe(name);
    } catch (e) {
      toast.error(`There was an error updating your name.`);
    }
    await meQuery.refetch();
    setIsSaving(false);
  };

  switch (isSyncing) {
    case 'sync':
      return (
        <Loader
          isGlobal
          message={
            <>
              Sync'ing your subscription status!
              <br />
              Please wait a few seconds
            </>
          }
        />
      );
    case 'payment':
      return <Loader isGlobal />;
  }

  return (
    <HeroCard title="Account" size="wide" fixedHeight>
      <div className={styles.root}>
        <ActionList
          items={[
            {
              id: 'details',
              icon: UserCircle,
              iconWidth: 'normal',
              label: 'My details',
              title: 'My details',
              content: <AccountDetails name={user.name} email={user.email} onSubmit={onSubmit} isSaving={isSaving} />,
            },
            {
              id: 'subscription',
              icon: LogoMark,
              iconWidth: 'normal',
              label: user.subscriptionType === 'FREE' ? 'Get premium subscription' : 'Manage subscription',
              title: 'Subscription',
              content: (
                <AccountSubscription
                  setIsLoading={(value) => setIsSyncing(value ? 'payment' : false)}
                  email={user.email}
                  variant={user.subscriptionType}
                  links={linksQuery.data}
                />
              ),
            },
            {
              id: 'invoices',
              icon: Receipt,
              iconWidth: 'narrow',
              label: 'Invoices',
              title: 'Invoices',
              content: (
                <AccountInvoices
                  isLoading={linksQuery.isLoading || linksQuery.isFetching}
                  invoices={linksQuery.data?.invoices ?? []}
                />
              ),
            },
            {
              id: 'about',
              icon: CommentSmile,
              iconWidth: 'normal',
              label: 'About',
              title: 'About',
              content: <AccountContact />,
            },
            {
              id: 'close',
              icon: Trash,
              iconWidth: 'normal',
              label: 'Close account',
              title: 'Close account',
              content: <AccountClose />,
            },
            {
              id: 'logout',
              icon: SignOut,
              iconWidth: 'normal',
              label: 'Sign out',
              title: 'Sign out',
              onClick: logout,
              content: null,
            },
          ]}
        />
      </div>
    </HeroCard>
  );
}
