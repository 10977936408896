import classNames from 'classnames';

import styles from './link.module.css';
import ChevronRight from '@/icons/fa/solid/chevron-right';

type LinkProps = {
  icon?: React.ComponentType<{ className?: string }>;
  iconWidth?: 'normal' | 'narrow';
  id: string;
  label: string;
  isOpen: boolean;
  onClick: (id: string) => void;
};

export function Link(props: LinkProps) {
  const { icon: Icon, isOpen, label, id, iconWidth, onClick } = props;

  return (
    <div className={classNames(styles.root, { [styles.active]: isOpen })} onClick={() => onClick(id)}>
      <div className={styles.iconWrapper}>
        {Icon && <Icon className={iconWidth === 'narrow' ? styles.narrow : styles.normal} />}
      </div>
      <div className={styles.label}>{label}</div>
      <div className={styles.chevronWrapper}>
        <ChevronRight />
      </div>
    </div>
  );
}
