import { useEffect, useState } from 'react';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { Form } from '@/components/form/form';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { MiniTriad } from '../mini-triad/mini-triad';

type AccountDetailsProps = {
  onSubmit: (name: string) => void;
  isSaving: boolean;
  name: string;
  email: string;
};

export function AccountDetails(props: AccountDetailsProps) {
  const { email, isSaving, onSubmit } = props;
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  return (
    <Card>
      <Form onSubmit={() => onSubmit(name)}>
        <Label text="Name">
          <Input
            disabled={isSaving}
            autoFocus={false}
            placeholder="Name"
            name="name"
            value={name}
            onChange={setName}
            testId="name"
          />
        </Label>

        <Label text="Email">
          <Input placeholder="Email" name="email" readOnly={true} value={email} disabled={true} testId="email" />
        </Label>

        <ActionBar>
          <Button icon={isSaving ? MiniTriad : undefined} type="submit" enabled={!isSaving}>
            Save
          </Button>
        </ActionBar>
      </Form>
    </Card>
  );
}
