import classNames from 'classnames';
import { ReactElement, useRef } from 'react';

import { Link } from './link/link';
import { Page } from './page/page';

import styles from './action-list.module.css';
import { useNavigate, useParams } from 'react-router-dom';

export type ActionListProps = {
  items: Array<{
    content: ReactElement | null;
    icon?: React.ComponentType<{ className?: string }>;
    iconWidth: 'normal' | 'narrow';
    id: string;
    label: string;
    onClick?: (id: string) => void;
    title: string;
  }>;
};

export function ActionList(props: ActionListProps) {
  const { items } = props;
  const rootRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { '*': open = '' } = useParams();

  const onClick = (id: string) => {
    navigate(`/account/${id}`);
  };

  return (
    <div className={styles.root} ref={rootRef}>
      <div className={classNames(styles.items, { [styles.itemsOpen]: open !== '' })}>
        {items.map((item, i) => (
          <Link
            icon={item.icon}
            iconWidth={item.iconWidth}
            id={item.id}
            isOpen={open === item.id}
            key={i}
            label={item.label}
            onClick={item.onClick ?? onClick}
          />
        ))}
      </div>

      <div className={styles.pages}>
        {items.map((item, i) => (
          <Page isOpen={open === item.id} onBack={() => onClick('')} title={item.title} key={i}>
            {item.content}
          </Page>
        ))}
      </div>
    </div>
  );
}
