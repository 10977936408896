import { Links } from '@/utilities/api';

import { Stripe } from './stripe/stripe';
import { Twitch } from './twitch/twitch';
import { RevenueCat } from './revenue-cat/revenue-cat';
import { Free } from './free/free';

type Type = 'FREE' | 'STRIPE' | 'REVENUE_CAT' | 'TWITCH';

type AccountSubscriptionProps = {
  email: string;
  variant: Type;
  links: Links;
  setIsLoading: (isLoading: boolean) => void;
};

export function AccountSubscription(props: AccountSubscriptionProps) {
  const { links, variant, email, setIsLoading } = props;

  switch (variant) {
    case 'FREE':
      return <Free links={links} email={email} setIsLoading={setIsLoading} />;
    case 'STRIPE':
      return <Stripe links={links} />;
    case 'TWITCH':
      return <Twitch />;
    case 'REVENUE_CAT':
      return <RevenueCat />;
    default:
      return null;
  }
}
