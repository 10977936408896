import { noop } from 'lodash';

import { Button } from '@/components/button/button';
import { Checkbox, CheckboxValue } from '@/components/checkbox/checkbox';
import { Form } from '@/components/form/form';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { Select } from '@/components/select/select';
import Search from '@/icons/fa/solid/search';

import cn from './filter-bar.module.css';

export type ValuePair = {
  label: string;
  value: string;
};

type FilterBarProps = {
  allLevels?: Array<ValuePair>;
  allTags: Array<ValuePair>;
  level?: string;
  tag: string;
  term: string;
  bookmarked?: CheckboxValue;
  showSearchButton: boolean;
  showBookmarked: boolean;
  showLevels: boolean;
  onSubmit: () => void;
  setLevel?: (level: string) => void;
  setTag: (tag: string) => void;
  setTerm: (term: string) => void;
  setBookmarked?: (bookmarked: CheckboxValue) => void;
};

export function FilterBar(props: FilterBarProps) {
  const {
    allLevels = [],
    allTags,
    bookmarked = 'off',
    level = '',
    showBookmarked,
    showLevels,
    showSearchButton,
    tag,
    term,
    onSubmit,
    setBookmarked = noop,
    setLevel = noop,
    setTag,
    setTerm,
  } = props;

  return (
    <Form onSubmit={onSubmit}>
      <div className={cn.root}>
        <div className={cn.inputColumn}>
          <Label text="Search" asSpan>
            <Input placeholder="Search" onChange={setTerm} value={term} />
          </Label>
        </div>
        <div className={cn.inputColumn}>
          <Label text="Tags" asSpan>
            <Select
              placeholder="Select tag"
              onChange={(tags) => setTag(String(tags))}
              value={tag}
              options={[{ label: 'All tags', value: '@@all' }, ...allTags]}
            />
          </Label>
        </div>
        {showLevels && (
          <div className={cn.inputColumn}>
            <Label text="Levels" asSpan>
              <Select
                placeholder="Select level"
                onChange={(levels) => setLevel(levels)}
                value={level}
                options={[{ label: 'All levels', value: '@@all' }, ...allLevels]}
              />
            </Label>
          </div>
        )}
        {showBookmarked && (
          <div className={cn.inputColumn}>
            <Label text="Bookmarked" asSpan>
              <div className={cn.bookmarked}>
                <Checkbox onChange={setBookmarked} value={bookmarked} label="Toggle" />
              </div>
            </Label>
          </div>
        )}
        {showSearchButton && (
          <div className={cn.searchColumn}>
            <Button category="primary" type="submit" icon={Search}>
              Search
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
}
