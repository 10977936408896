import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { getWelcomePath } from '@/utilities/paths';
import { mailAdmin } from '@/config';
import { Stack } from '@/components/stack/stack';
import { Text } from '@/components/text/text';
import { H1 } from '@/components/h1/h1';

import style from './global-error.module.css';

export function GlobalError() {
  return (
    <>
      <div className={style.root} id="global-error">
        <div className={style.wrap}>
          <Card>
            <Stack>
              <H1>Whoops! Looks like something went wrong..</H1>
              <Text>We'll look into it! If it continues to happen please let us know.</Text>
              <ActionBar>
                <Button category="secondary" asAnchor href={`mailto:${mailAdmin}`}>
                  Let us know
                </Button>
                <Button asAnchor href={getWelcomePath()}>
                  Return home
                </Button>
              </ActionBar>
            </Stack>
          </Card>
        </div>
      </div>
      <span
        onClick={() => {
          // @ts-ignore
          x.y.z = 3;
        }}
        className={style.debug}
      >
        π
      </span>
    </>
  );
}
