import classNames from 'classnames';

import ExclamationTriangle from '@/icons/fa/solid/exclamation-triangle';
import InfoCircle from '@/icons/fa/solid/info-circle';
import { TextV2 } from '@/components/text/text';

import style from './note.module.scss';

type NoteProps = {
  body: string;
  category?: 'normal' | 'exception' | 'info';
  images?: { style: {} }[];
  title?: string;
};

export function Note(props: NoteProps) {
  const { category = 'normal', images = [], body, title } = props;

  const renderHeading = (title: null | string = null) => {
    switch (category) {
      case 'exception':
        return (
          <h2 className={style.heading}>
            <ExclamationTriangle className={style.icon} />
            Exception
            {title && (
              <>
                <span>-</span>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </>
            )}
          </h2>
        );
      case 'info':
        return (
          <h2 className={style.heading}>
            <InfoCircle className={style.icon} />
            Info
            {title && (
              <>
                <span>-</span>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </>
            )}
          </h2>
        );
      default:
        if (title) {
          return (
            <h2 className={style.heading}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </h2>
          );
        } else {
          return null;
        }
    }
  };

  const classes = classNames(style.root, { [style.hasImage]: images.length > 0 }, style[category]);

  return (
    <div className={classes}>
      <div className={style.container}>
        <div className={style.content}>
          {renderHeading(title)}
          <TextV2 text={body} />
        </div>
        {images.length > 0 && (
          <div className={style.images}>
            {images.map((image, i) => (
              <div className={style.image} key={i} style={image.style} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
