import classNames from 'classnames';

import styles from './skeleton.module.css';

type SkeletonProps = {
  type: 'text' | 'link-icon' | 'progress';
};

export function Skeleton(props: SkeletonProps) {
  const { type } = props;
  return (
    <div className={classNames(styles.root, styles[type])}>
      <div />
    </div>
  );
}
