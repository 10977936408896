import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { Skeleton } from '@/components/skeleton/skeleton';
import FilePdf from '@/icons/fa/solid/file-pdf';
import Table from '@/components/table/table';

import styles from './account-invoices.module.css';

type AccountInvoicesProps = {
  invoices: Array<{
    id: string;
    date: string;
    link: string;
  }>;
  isLoading: boolean;
};

export function AccountInvoices(props: AccountInvoicesProps) {
  const { invoices = [], isLoading } = props;

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Card>
          <Table
            items={[
              [<Skeleton type="text" key="1" />, <Skeleton type="link-icon" key="2" />],
              [<Skeleton type="text" key="1" />, <Skeleton type="link-icon" key="2" />],
              [<Skeleton type="text" key="1" />, <Skeleton type="link-icon" key="2" />],
            ]}
          />
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Card>
        {invoices.length > 0 ? (
          <Table
            items={invoices.map((invoice) => [
              invoice.date,
              <Button asAnchor key={invoice.id} category="link" href={invoice.link} icon={FilePdf}>
                Download
              </Button>,
            ])}
          />
        ) : (
          <div>No invoices</div>
        )}
      </Card>
    </div>
  );
}
