import ChevronDown from '@/icons/fa/solid/chevron-down';

import style from './select.module.scss';

type SelectProps = {
  isDisabled?: boolean;
  labelKey?: string;
  onChange: (value: string | number) => void;
  options: Array<Record<string, any>>;
  placeholder?: string;
  value: string | number | null;
  valueKey?: string;
};

export function Select(props: SelectProps) {
  const { isDisabled = false, labelKey = 'label', onChange, options, placeholder, value, valueKey = 'value' } = props;
  const hasValue = value && options.find((option) => option[valueKey] === value);

  return (
    <div className={style.root}>
      <select
        className={style.select}
        disabled={isDisabled}
        onChange={(e) => onChange(e.currentTarget.value)}
        value={hasValue ? value : '@@placeholder'}
      >
        {placeholder !== undefined && (
          <option disabled value="@@placeholder" key="@@placeholder">
            {placeholder}
          </option>
        )}
        {options.map((option, i) => (
          <option key={i} value={option[valueKey]}>
            {option[labelKey]}
          </option>
        ))}
      </select>
      <ChevronDown className={style.icon} />
    </div>
  );
}
