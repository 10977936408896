import classNames from 'classnames';

import { IconProps } from '@/icons/icon-props';

import style from './tags.module.scss';

type TagsProps = {
  icon?: (props: IconProps) => JSX.Element;
  idKey?: string;
  items: Array<any>;
  labelKey?: string;
};

export function Tags(props: TagsProps) {
  const { icon: Icon, items = [], labelKey = 'label' } = props;

  return (
    <ul className={classNames(style.root, { [style.hasIcon]: Icon })}>
      {items.map((item, i) => (
        <li title={item[labelKey]} className={style.tag} key={i}>
          {Icon && <Icon className={style.icon} />}
          <span>{item[labelKey]}</span>
        </li>
      ))}
    </ul>
  );
}
