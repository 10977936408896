import { VocabularyCard } from '@/components/vocabulary-card/vocabulary-card';

import { SearchResponse } from '../vocabulary';
import styles from './results.module.css';

type ResultsProps = {
  items: SearchResponse['items'];
};

export function Results(props: ResultsProps) {
  const { items } = props;

  return (
    <div className={styles.root}>
      {items.map((item) => (
        <VocabularyCard {...item} key={item.id} />
      ))}
    </div>
  );
}
