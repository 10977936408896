import { IconProps } from './icon-props';

export default function LogoMark({ className = '' }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 181 181">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.52 90.2599C180.52 140.109 140.109 180.52 90.2599 180.52C40.4107 180.52 0 140.109 0 90.2599C0 40.4107 40.4107 0 90.2599 0C140.109 0 180.52 40.4107 180.52 90.2599ZM37.3628 127.045C21.4533 105.765 63.8113 35.2839 90.2599 35.2839C116.708 35.2839 159.066 105.765 143.157 127.045C139.115 132.445 131.29 136.458 121.617 139.086V104.437C121.609 102.31 120.761 100.273 119.257 98.7695C117.754 97.2658 115.717 96.4176 113.59 96.41H66.9297C64.8032 96.4176 62.7659 97.2658 61.2622 98.7695C59.7585 100.273 58.9104 102.31 58.9028 104.437V139.086C49.23 136.458 41.4052 132.445 37.3628 127.045Z"
      />
    </svg>
  );
}
