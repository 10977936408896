import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { Label } from '@/components/label/label';
import { Stack } from '@/components/stack/stack';
import ButtonGroup from '@/components/button-group/button-group';
import { H2 } from '@/components/h2/h2';

type SetupProps = {
  begin: () => void;
  difficulty: number;
  hiraganaMode: boolean;
  japaneseMode: boolean;
  setDifficulty: (value: number) => void;
  setHiraganaMode: (value: boolean) => void;
  setJapaneseMode: (value: boolean) => void;
};

export function Setup(props: SetupProps) {
  const { hiraganaMode, japaneseMode, difficulty, setHiraganaMode, setJapaneseMode, setDifficulty, begin } = props;

  return (
    <Card>
      <Stack>
        <H2>Setup</H2>
        <Label asSpan text="Difficulty">
          <ButtonGroup
            onChange={(value: string) => setDifficulty(Number(value))}
            value={difficulty.toString()}
            testId="difficulty"
            items={[
              { label: 'Easy', value: '4' },
              { label: 'Medium', value: '8' },
              { label: 'Hard', value: '12' },
            ]}
          />
        </Label>
        <Label asSpan text="Kana focus">
          <ButtonGroup
            onChange={(value: string) => setHiraganaMode(value === 'true')}
            value={hiraganaMode.toString()}
            items={[
              { label: 'Hiragana', value: 'true' },
              { label: 'Katakana', value: 'false' },
            ]}
          />
        </Label>
        <Label asSpan text="Language direction">
          <ButtonGroup
            onChange={(value: string) => setJapaneseMode(value === 'true')}
            value={japaneseMode.toString()}
            items={[
              { label: 'To English', value: 'true' },
              { label: 'To Japanese', value: 'false' },
            ]}
          />
        </Label>
        <ActionBar>
          <Button onClick={begin} category="primary" testId="kana-setup-begin">
            Begin
          </Button>
        </ActionBar>
      </Stack>
    </Card>
  );
}
