import AudioPlayer from 'react-h5-audio-player';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-h5-audio-player/lib/styles.css';

import { useMarkStoryAsRead, useStory } from '@/utilities/api';
import { getStoriesPath } from '@/utilities/paths';
import { HeroCard } from '@/components/hero-card/hero-card';
import { isMobile } from '@/config';
import { Loader } from '@/components/loader/loader';
import { Toolbar } from '@/components/toolbar/toolbar';
import ButtonGroup from '@/components/button-group/button-group';
import Play from '@/icons/fa/solid/play';
import Pause from '@/icons/fa/solid/pause';
import Redo from '@/icons/fa/light/redo';
import Undo from '@/icons/fa/light/undo';

import { Line } from './line/line';
import styles from './story.module.css';

import './story.css';

export type Mode = 'none' | 'english' | 'kana' | 'vocabulary' | 'grammar';

export default function Story() {
  const { storyId = '' } = useParams();
  const { isLoading, isSuccess, data } = useStory(storyId);
  const isReady = !isLoading && isSuccess && data;
  const [mode, setMode] = useState<Mode>('none');
  const [currentLine, setCurrentLine] = useState<number>(-1);
  const { mutate } = useMarkStoryAsRead(storyId);

  const onListen = (event: Event) => {
    // @ts-ignore
    const position = event.target?.currentTime ?? 0;

    const index = lines.findIndex((line) => {
      if (position >= line.timestampFrom && position <= line.timestampTo) {
        return true;
      }
    });

    setCurrentLine(index);
  };

  useEffect(() => {
    mutate();
  }, [mutate]);

  if (!isReady) {
    return <Loader isGlobal />;
  }

  const lines = data?.content?.lines ?? [];

  return (
    <HeroCard
      size="medium"
      title={data.title}
      byline={data.englishTitle}
      breadcrumbs={[{ label: 'Stories', href: getStoriesPath() }]}
    >
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.image}>
            <img src={data?.image + '?v=1'} alt="" />
          </div>
          <div className={styles.content}>
            {lines.map((line, index) => (
              <Line line={line} mode={mode} isHighlighted={index === currentLine} key={index} />
            ))}
          </div>
        </div>
      </div>
      <Toolbar>
        <div className={styles.toolbar}>
          <AudioPlayer
            src={data?.audio}
            listenInterval={500}
            onListen={onListen}
            onSeeked={onListen}
            layout="horizontal"
            customAdditionalControls={[]}
            customVolumeControls={[]}
            customProgressBarSection={isMobile ? [] : undefined}
            customIcons={{
              play: <Play />,
              pause: <Pause />,
              rewind: <Undo />,
              forward: <Redo />,
            }}
          />
          <ButtonGroup
            testId="story-mode"
            items={[
              { value: 'none', label: 'None' },
              { value: 'english', label: 'English' },
              { value: 'kana', label: 'Kana' },
              { value: 'vocabulary', label: 'Vocab' },
              { value: 'grammar', label: 'Grammar' },
            ]}
            onChange={(value) => setMode(value as typeof mode)}
            value={mode}
          />
        </div>
      </Toolbar>
    </HeroCard>
  );
}
