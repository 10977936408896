import { sample } from 'lodash';

import { joinDelim } from '@/utilities/formatters';

// -- Correct text ---------------------------------------------------------------------------------
const correctMessages = [
  'Correct, well done!',
  // Word order keeps changing / pver calling this function which changes the output on the fly :(
  // "That's correct, fantastic job!",
  // 'Correct! Keep up the great work.',
  // 'Great stuff! That is correct',
];
export const getCorrectMessage = (): string => sample(correctMessages) as string;

// -- Incorrect text -------------------------------------------------------------------------------
// prettier-ignore
export const getIncorrectMessage = (v: string[] | string) => `Whoops! The correct answer is ${joinDelim(v)}`;

// -- Preferred text -------------------------------------------------------------------------------
export const getPreferredMessage = (
  acceptableAnswer: string,
  preferredAnswer: Array<string> | string,
  delimiter: string = ' or '
) => {
  let preferred = preferredAnswer;
  if (preferredAnswer instanceof Array) {
    preferred = preferredAnswer.join(delimiter);
  }
  return `${acceptableAnswer} is correct, but ${preferred} is preferred`;
};

// prettier-ignore
export const content = {
  page404: {
    heading: `Whoops! We couldn't find that..`,
    body: `Perhaps try to clicking one of the links above to get back on track`,
  },
  interactions: {
    money: {
      heading: `How much money is this?`,
      instructions: 'Pick a mix of coins/notes that total to the amount you hear',
    },
    'word-order': {
      instructions: 'Click the missing words to place them in the correct order',
    },
  },
}
