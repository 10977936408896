import { ReactNode } from 'react';
import classNames from 'classnames';

import { Text } from '@/components/text/text';
import Times from '@/icons/fa/solid/times';
import CheckIcon from '@/icons/fa/solid/check';

import style from './check.module.scss';

type CheckProps = {
  id?: string;
  selected?: boolean;
  correct?: boolean;
  complete?: boolean;
  enabled?: boolean;
  onClick?: Function;
  text: ReactNode;
  subtext?: ReactNode;
};

export function Check(props: CheckProps) {
  const {
    onClick: callback,
    id,
    correct = false,
    complete = false,
    enabled = true,
    selected = false,
    text,
    subtext,
  } = props;

  const onClick = (e: Object) => {
    if (enabled && callback) {
      callback(id);
    }
  };

  return (
    <label
      key={id}
      onClick={onClick}
      data-testid={`multiple-choice-answer-${id}`}
      className={classNames(style.root, {
        [style.correct]: correct === true,
        [style.incorrect]: correct === false,
        [style.selected]: selected === true,
        [style.unselected]: selected === false,
        [style.complete]: complete === true,
        [style.incomplete]: complete === false,
        [style.enabled]: enabled === true,
        [style.disabled]: enabled === false,
      })}
    >
      <div className={style.iconWrapper}>
        <Times className={style.icon} />
        <CheckIcon className={style.icon} />
        <div data-testid="multiple-choice-dot" className={style.dot} />
      </div>
      <div className={style.content}>
        <Text className={style.text}>{text}</Text>
        {subtext && <Text className={style.subtext}>{subtext}</Text>}
      </div>
    </label>
  );
}
