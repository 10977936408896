import { Chip, ChipType } from '@/components/chip/chip';

import cn from './lesson-chips.module.css';

export const tagMap: Record<string, ChipType> = {
  'Absolute Beginner': 'pink',
  Quiz: 'grey',
  N5: 'orange',
  N4: 'red',
  N3: 'purple',
  N2: 'green',
  N1: 'blue',
};

type LessonProps = {
  isLocked: boolean;
  tags: Array<string>;
};

export function LessonChips(props: LessonProps) {
  const { isLocked, tags } = props;

  return (
    <div className={cn.root} data-testid="lesson-chips">
      {tags.map((tag) => (
        <Chip hasMarker type={isLocked ? 'disabled' : tagMap[tag] ?? 'blue'} key={tag}>
          {tag}
        </Chip>
      ))}
    </div>
  );
}
