import classNames from 'classnames';

import style from './h1.module.css';

type Props = {
  className?: string;
  children?: any;
  inverted?: boolean;
};

export function H1(props: Props) {
  const { className, children, inverted = false } = props;
  return <h1 className={classNames(style.root, { [style.inverted]: inverted }, className)}>{children}</h1>;
}
