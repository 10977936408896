import * as React from 'react';
import classNames from 'classnames';

import { TextV2 } from '@/components/text/text';
import Minus from '@/icons/fa/regular/minus';
import Plus from '@/icons/fa/regular/plus';

import style from './vocab-list.module.scss';

interface IProps {
  className?: string;
  rows: [string, string][];
  defaultOpen?: boolean;
}

interface IState {
  open: boolean;
}

export default class VocabList extends React.Component<IProps, IState> {
  static defaultProps = {
    items: [],
  };

  constructor(props: IProps) {
    super(props);
    this.state = { open: props.defaultOpen === true };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { className, rows = [] } = this.props;
    const { open } = this.state;

    return (
      <div className={classNames(style.root, className)}>
        <h2 className={style.heading} onClick={this.toggle}>
          <span>Vocab List</span>
          {open ? <Minus className={style.icon} /> : <Plus className={style.icon} />}
        </h2>
        {open && (
          <div className={style.rows}>
            {rows.map(([left, right], key) => (
              <div className={style.row} key={key}>
                <div className={style.item}>
                  <TextV2 text={left} />
                </div>
                <div className={style.item} dangerouslySetInnerHTML={{ __html: right }} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
