import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { getLessonsPath } from '@/utilities/paths';
import { H1 } from '@/components/h1/h1';
import { HeroCard } from '@/components/hero-card/hero-card';
import { InteractionBlock } from '@/components/interaction-block/interaction-block';
import { Lesson as LessonComponent } from '@/components/lesson/lesson';
import { Loader } from '@/components/loader/loader';
import { Modal } from '@/components/modal/modal';
import { StreakInfo } from '@/components/streak-info/streak-info';
import { toggleBookmark, useMarkLessonAsRead } from '@/utilities/api';
import { useExpressLesson, useExpressLessons } from '@/utilities/api';
import InfoCircle from '@/icons/fa/light/info-circle';

import cn from './lesson.module.css';

export default function Lesson() {
  const { lessonId = '' } = useParams();
  const { isLoading, isSuccess, data, refetch, isRefetching } = useExpressLesson(lessonId);
  const { refetch: refetchExpressLessons } = useExpressLessons();
  const [isSavingBookmark, setIsSavingBookmark] = useState(false);
  const { mutate } = useMarkLessonAsRead(lessonId);

  const isReady = !isLoading && isSuccess && data;
  const upPath = getLessonsPath();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    mutate();
  }, [mutate]);

  // TODO https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
  // This is a bit lazy but stops a weird star flicker when navigating back and forward
  const onBookmark = async () => {
    if (isSavingBookmark) {
      return;
    }
    setIsSavingBookmark(true);
    await toggleBookmark(lessonId);
    setIsSavingBookmark(false);
    refetch();
    refetchExpressLessons();
  };

  if (!isReady) {
    return (
      <HeroCard size="medium" title="">
        <Loader isGlobal />
      </HeroCard>
    );
  }

  const { name, interactions, ...props } = data;

  return (
    <HeroCard size="medium" title={name} breadcrumbs={[{ label: 'Lessons', href: upPath }]}>
      <div className={cn.root}>
        <Card>
          <div className={cn.content}>
            <LessonComponent {...props} isSavingBookmark={isSavingBookmark || isRefetching} onBookmark={onBookmark} />
            {interactions.length > 0 && (
              <div className={cn.quizWrapper}>
                <H1>Quiz{interactions.length > 1 && 'zes'}</H1>
                <Modal
                  heading="What is spaced repetition?"
                  confirmLabel="Got it!"
                  trigger={
                    <Button small category="secondary" testId="lesson-spaced-repetition">
                      <InfoCircle className={cn.icon} />
                      Spaced repetition
                    </Button>
                  }
                >
                  <StreakInfo />
                </Modal>
                <div className={cn.quizzes}>
                  {interactions.map((interaction, i) => (
                    <InteractionBlock key={i} name={`Quiz ${i + 1}`} interaction={interaction} onComplete={refetch} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </HeroCard>
  );
}
