import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { get, useQueryMe } from '@/utilities/api';
import { getLessonPath, getPracticePath, getStoryPath } from '@/utilities/paths';
import { H1 } from '@/components/h1/h1';
import { HeroCard } from '@/components/hero-card/hero-card';
import { Label } from '@/components/label/label';
import { LANDING_URL, mailAdmin } from '@/config';
import { ProgressBar } from '@/components/progress-bar/progress-bar';
import { Skeleton } from '@/components/skeleton/skeleton';
import { Stack } from '@/components/stack/stack';
import { SupportButton } from '@/components/support-button/support-button';
import { useAuthenticatedLayoutContext } from '@/layouts/authenticated-layout/authenticated-layout';
import { useDriver } from '@/hooks/use-driver';
import CheckCircle from '@/icons/fa/solid/check-circle';
import Circle from '@/icons/fa/solid/circle';
import Discord from '@/icons/fa/brands/discord';
import Facebook from '@/icons/fa/brands/facebook-f';
import Instagram from '@/icons/fa/brands/instagram';
import Twitter from '@/icons/fa/brands/twitter';

import styles from './welcome.module.css';

type DashboardStats = {
  storiesRead: number;
  storiesTotal: number;
  lessonsRead: number;
  lessonsTotal: number;
};

const checklistItemsData = [
  { id: 'signup', label: 'Sign up', buttonText: 'Complete', path: '' },
  { id: 'firstStory', label: 'Read the 1st story ', buttonText: 'Read story', path: getStoryPath('pz') },
  {
    id: 'firstIntroLesson',
    label: 'Take the 1st introduction lesson (General features of Japanese)',
    buttonText: 'View lesson',
    path: getLessonPath('gw'),
  },
  { id: 'practiceHiragana', label: 'Practice hiragana', buttonText: 'Practice now', path: getPracticePath('kana') },
  {
    id: 'firstBeginnerLesson',
    label: 'Take the 1st Beginner lesson (XはYです）',
    buttonText: 'View lesson',
    path: getLessonPath('vw'),
  },
] as const;

export default function Welcome() {
  const { user } = useAuthenticatedLayoutContext();
  const [isOpen, setIsOpen] = useState<boolean>(!user.onboarding.checklistComplete);
  const dashboardStats = useQuery(['dashboard-stats'], () => get<DashboardStats>('dashboard-stats'));
  const driver = useDriver('welcome');
  const { refetch } = useQueryMe();

  // After the user completes parts of the checklist, we want to refetch the user data to update the onboarding state
  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <HeroCard title="Welcome to ImaWakatta!" size="medium">
      <div className={styles.root}>
        <div className={styles.card}>
          <Card>
            <div className={classNames(styles.checklist, { [styles.isOpen]: isOpen })}>
              <div className={styles.checklistHeader}>
                <div className={styles.checklistHeaderText}>
                  {user.onboarding.checklistComplete ? (
                    <>
                      <CheckCircle className={styles.checklistItemCompleteIcon} />
                      Setup complete ({user.onboarding.checklistCompleteCount}/{user.onboarding.checklistTotal})
                    </>
                  ) : (
                    <>
                      <Circle className={styles.checklistItemIncompleteIcon} />
                      Setup checklist ({user.onboarding.checklistCompleteCount}/{user.onboarding.checklistTotal})
                    </>
                  )}
                </div>
                <Button category="secondary" testId="welcome-toggle" small onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? 'Hide' : 'Show'}
                </Button>
              </div>
              <div className={styles.checklistWrapper} data-testid="welcome-wrapper">
                {checklistItemsData.map((item) => (
                  <div className={styles.checklistItem} key={item.id}>
                    <div className={styles.checklistItemIcon}>
                      {user.onboarding.checklist[item.id] ? (
                        <CheckCircle className={styles.checklistItemCompleteIcon} testId="welcome-complete" />
                      ) : (
                        <Circle className={styles.checklistItemIncompleteIcon} testId="welcome-incomplete" />
                      )}
                    </div>
                    <div className={styles.checklistContent}>
                      <div className={styles.checklistLabel}>{item.label}</div>
                      <div className={styles.checklistButton}>
                        {!user.onboarding.checklist[item.id] && (
                          <Button href={item.path} small category="secondary" testId={`welcome-${item.id}`}>
                            {item.buttonText}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </div>

        <div className={styles.card}>
          <Card>
            <Stack gap="space-3">
              <H1>Progress</H1>
              <Stack gap="space-1">
                <Label text="Stories read">
                  {dashboardStats.data ? (
                    <ProgressBar current={dashboardStats.data.storiesRead} total={dashboardStats.data.storiesTotal} />
                  ) : (
                    <Skeleton type="progress" />
                  )}
                </Label>
              </Stack>
              <Stack gap="space-1">
                <Label text="Lessons completed">
                  {dashboardStats.data ? (
                    <ProgressBar current={dashboardStats.data.lessonsRead} total={dashboardStats.data.lessonsTotal} />
                  ) : (
                    <Skeleton type="progress" />
                  )}
                </Label>
              </Stack>
            </Stack>
          </Card>
        </div>

        <ul className={styles.socials}>
          <li>
            <a
              aria-label="Visit us on Instagram"
              href="https://www.instagram.com/imawakatta"
              className={styles.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <Instagram className={styles.instagram} />
            </a>
          </li>
          <li>
            <a
              aria-label="Join us on Discord"
              href="https://discord.gg/8hw73Zt6Az"
              className={styles.socialLink}
              target="_blank"
              rel="noreferrer"
              data-testid="mobile-nav-discuss"
            >
              <Discord className={styles.instagram} />
            </a>
          </li>
          <li>
            <a
              aria-label="Visit us on Twitter"
              href="https://twitter.com/imawakattaapp"
              className={styles.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <Twitter className={styles.twitter} />
            </a>
          </li>
          <li>
            <a
              aria-label="Visit us on Facebook"
              href="https://www.facebook.com/ImaWakatta"
              className={styles.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <Facebook className={styles.facebook} />
            </a>
          </li>
        </ul>
        <ul className={styles.links}>
          <li>
            <a href={`${LANDING_URL}/about`} className={styles.link}>
              About
            </a>
          </li>
          <li>
            <a href={`mailto:${mailAdmin}`} className={styles.link}>
              Contact us
            </a>
          </li>
        </ul>
      </div>
      <SupportButton driver={driver} />
    </HeroCard>
  );
}
