import { forwardRef, HTMLInputTypeAttribute } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';

import { IconProps } from '@/icons/icon-props';

import style from './input.module.css';

type Props = {
  accept?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  icon?: (props: IconProps) => JSX.Element;
  name?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onChange?: (value: string, e: KeyboardEvent) => void;
  onKeyUp?: (value: string, e: KeyboardEvent) => void;
  placeholder?: string;
  readOnly?: boolean;
  size?: number;
  tabIndex?: number;
  testId?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  variant?: 'regular' | 'large';
};

export const Input = forwardRef<HTMLInputElement, Props>(function Input(props, ref) {
  const {
    accept,
    autoFocus = false,
    disabled = false,
    icon: Icon,
    name,
    tabIndex,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    onKeyUp = noop,
    placeholder = '',
    readOnly = false,
    size = 1,
    type = 'text',
    testId,
    value,
    variant = 'regular',
  } = props;

  return (
    <div className={classNames(style.root, { [style.hasIcon]: Icon, [style.large]: variant === 'large' })}>
      <input
        accept={accept}
        autoCapitalize="off"
        autoFocus={autoFocus}
        className={style.input}
        data-testid={testId}
        disabled={disabled}
        name={name}
        onBlur={(e) => onBlur(e.currentTarget.value)}
        onFocus={(e) => onFocus(e.currentTarget.value)}
        onChange={(e) => onChange(e.currentTarget.value, e)}
        onKeyUp={(e) => onKeyUp(e.currentTarget.value, e)}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        size={size}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
      {Icon && <Icon className={style.icon} />}
    </div>
  );
});
