import { Card } from '@/components/card/card';
import { Chip } from '@/components/chip/chip';
import { HeroCard } from '@/components/hero-card/hero-card';
import { ImageCard } from '@/components/image-card/image-card';
import { useAuthenticatedLayoutContext } from '@/layouts/authenticated-layout/authenticated-layout';

import styles from './practice.module.css';
import { getBillingPath } from '@/utilities/paths';

const practice = [
  {
    isPremium: false,
    image: '/assets/emoji/japanese-service-charge-button.png',
    description: 'Practice Hiragana and Katakana',
    label: 'Hiragana/Katakana',
    url: '/practice/kana',
  },
  {
    isPremium: true,
    image: '/assets/emoji/mantelpiece-clock.png',
    description: 'Practice your Japanese time telling vocabulary',
    label: 'Time',
    url: '/practice/time',
  },
  {
    isPremium: true,
    image: '/assets/emoji/writing-hand.png',
    description: 'Practice verb conjugations across all Japanese levels',
    label: 'Conjugation',
    url: '/practice/conjugation',
  },
];

export default function Practice() {
  const { user } = useAuthenticatedLayoutContext();
  const { isPremium } = user;

  return (
    <HeroCard size="wide" title="Practice">
      <div className={styles.root}>
        <Card>
          <div className={styles.content}>
            <p>Practice your skills with practical, interactive games and quizzes.</p>
          </div>
        </Card>
        <div className={styles.items}>
          {practice.map((item, i) => {
            const isLocked = !isPremium && item.isPremium;
            return (
              <ImageCard
                cover={false}
                to={isLocked ? getBillingPath() : item.url}
                imageUrl={item.image}
                buttonLabel="Practice now"
                key={i}
              >
                <strong className={styles.title}>{item.label}</strong>
                <div className={styles.tags}>{isLocked && <Chip type="info">Premium only</Chip>}</div>
                <p className={styles.byline}>{item.description}</p>
              </ImageCard>
            );
          })}
        </div>
      </div>
    </HeroCard>
  );
}
