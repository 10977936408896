import { ReactNode } from 'react';

import styles from './label.module.css';

type Props = {
  children?: ReactNode;
  text: string;
  asSpan?: boolean; // Some components, like ButtonGroup, should avoid label for hovering reasons
};

export const Label = (props: Props) => {
  const { asSpan = false, children, text } = props;

  const Component = asSpan ? 'span' : 'label';

  return (
    <Component className={styles.root}>
      <div className={styles.label}>{text}</div>
      {children}
    </Component>
  );
};
