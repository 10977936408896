import { useRef } from 'react';

import { H2 } from '@/components/h2/h2';
import { Audio, AudioRef } from '@/components/audio/audio';
import { Stack } from '@/components/stack/stack';
import {
  hiraganaTable,
  hiraganaYaTable,
  katakanaTable,
  katakanaAdvancedTable,
  katakanaTenTable,
  hiraganaTenTable,
  katakanaYaTable,
} from '@/config';

import style from './kana-table.module.scss';

export type TableKey =
  | 'core-hiragana'
  | 'tenten-hiragana'
  | 'yayuyo-hiragana'
  | 'core-katakana'
  | 'tenten-katakana'
  | 'yayuyo-katakana'
  | 'advanced-katakana';

type KanaTableProps = {
  category: TableKey;
};

type KanaCellProps = {
  rowIndex: number;
  cellIndex: number;
  category: TableKey;
};

export const data: Record<TableKey, { title: string; folder: string; cells: Array<Array<string>> }> = {
  'core-hiragana': { title: 'Core hiragana', folder: 'core', cells: hiraganaTable },
  'tenten-hiragana': { title: 'Tenten ( ゛) and maru ( ゜) hiragana', folder: 'tenten', cells: hiraganaTenTable },
  'yayuyo-hiragana': { title: 'Consonant + ya, yu, yo hiragana', folder: 'small', cells: hiraganaYaTable },
  'core-katakana': { title: 'Core katakana', folder: 'core', cells: katakanaTable },
  'tenten-katakana': { title: 'Tenten ( ゛) and maru ( ゜) katakana', folder: 'tenten', cells: katakanaTenTable },
  'yayuyo-katakana': { title: 'Consonant + ya, yu, yo, e katakana', folder: 'small', cells: katakanaYaTable },
  'advanced-katakana': { title: 'Advanced katakana', folder: 'advanced-katakana', cells: katakanaAdvancedTable },
};

function KanaCell(props: KanaCellProps) {
  const audioRef = useRef<AudioRef>(null);
  const { category, cellIndex, rowIndex } = props;
  const table = data[category];

  const japanese = table.cells[rowIndex][cellIndex];
  const english = table.cells[rowIndex + 1][cellIndex];

  return (
    <div className={style.kana} onClick={() => audioRef.current?.play()}>
      <span className={style.jp}>{japanese}</span>
      <span className={style.en}>{english}</span>
      {english !== '' && (
        <Audio
          ref={audioRef}
          preload={false}
          variant="small"
          url={`https://static.imawakatta.com/audio/manual/kana/${table.folder}/${english}.m4a`}
        />
      )}
    </div>
  );
}

export function KanaTable(props: KanaTableProps) {
  const { category } = props;
  const table = data[category];

  return (
    <Stack>
      <H2>{table.title}</H2>

      <div>
        <div className={style.row}>
          <div className={style.heading}>A</div>
          <div className={style.heading}>I</div>
          <div className={style.heading}>U</div>
          <div className={style.heading}>E</div>
          <div className={style.heading}>O</div>
        </div>

        {table.cells.map((row, rowIndex) => {
          // Skip English rows, they are accessed as an offset of this row
          if (rowIndex % 2 !== 0) return;

          return (
            <div className={style.row} key={rowIndex}>
              {row.map((_, cellIndex) => (
                <KanaCell key={cellIndex} category={category} cellIndex={cellIndex} rowIndex={rowIndex} />
              ))}
            </div>
          );
        })}
      </div>
    </Stack>
  );
}
