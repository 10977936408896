import { useState } from 'react';

import { ActionBar } from '@/components/action-bar/action-bar';
import { isMobile } from '@/config';
import { Button } from '@/components/button/button';
import { Divvy } from '@/components/divvy/divvy';
import { Form } from '@/components/form/form';
import { FormError } from '@/components/form-error/form-error';
import { getWelcomePath } from '@/utilities/paths';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { Loader } from '@/components/loader/loader';
import { login, mobileLogin } from '@/utilities/api';
import { redirectWithFlash } from '@/utilities/flash';
import { SimpleLink } from '@/components/simple-link/simple-link';
import { Stack } from '@/components/stack/stack';
import { Text } from '@/components/text/text';
import { UnauthenticatedLayout } from '@/layouts/unauthenticated-layout/unauthenticated-layout';
import { useRedirectAuthenticatedUser } from '@/hooks/use-redirect-authenticated-user';
import { Sso } from '@/components/sso/Sso';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  useRedirectAuthenticatedUser();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setEmailError(null);
    setPasswordError(null);
    setIsLoading(true);

    try {
      if (isMobile) {
        await mobileLogin(email, password);
      } else {
        await login(email, password);
      }
      redirectWithFlash(getWelcomePath(), 'Welcome back!', 'success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setEmailError(e?.response?.data?.errors?.email?.[0]);
      setPasswordError(e?.response?.data?.errors?.password?.[0]);
      setIsLoading(false);
    }
  };

  return (
    <UnauthenticatedLayout>
      {isLoading && <Loader />}
      <Form onSubmit={onSubmit} heading="Sign in">
        <Label text="Email address">
          <Input
            onChange={setEmail}
            placeholder="name@example.com"
            type="text"
            value={email}
            variant="large"
            testId="email"
          />
          {emailError && <FormError>{emailError}</FormError>}
        </Label>
        <Label text="Password">
          <Input
            onChange={setPassword}
            placeholder="Password"
            type="password"
            value={password}
            variant="large"
            testId="password"
          />
          {passwordError && <FormError>{passwordError}</FormError>}
          <SimpleLink testId="forgot" to="/password/reset">
            Forgot password?
          </SimpleLink>
        </Label>
        <Button type="submit" testId="submit">
          Sign in
        </Button>
      </Form>
      <Stack gap="space-3">
        <Divvy text="OR" />
        <Sso />
        <ActionBar variant="center">
          <Text>
            No account? <SimpleLink to="/register">Create an account</SimpleLink>
          </Text>
        </ActionBar>
      </Stack>
    </UnauthenticatedLayout>
  );
}
