import { IconProps } from '@/icons/icon-props';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import style from './well.module.css';

type WellProps = {
  icon: (props: IconProps) => JSX.Element;
  onClick?: () => void;
  href?: string;
  to?: string;
  primary: string;
  secondary?: string;
  includeBoxShadow?: boolean;
};

export function Well(props: WellProps) {
  const { icon: Icon, primary, secondary, onClick, href, to, includeBoxShadow = false } = props;

  let action = { onClick, href };
  if (!onClick) {
    action.onClick = onClick;
  }
  if (!href) {
    action.href = href;
  }

  const WellContent = (
    <>
      <span className={style.logoDisc}>
        <Icon className={style.icon} />
      </span>
      <strong className={style.primary}>{primary}</strong>
      <span className={style.secondary}>{secondary}</span>
    </>
  );

  if (to) {
    return (
      <Link to={to} className={classNames(style.root, { [style.includeBoxShadow]: includeBoxShadow }, style.active)}>
        {WellContent}
      </Link>
    );
  } else if (href) {
    return (
      <Link to={href} className={classNames(style.root, { [style.includeBoxShadow]: includeBoxShadow }, style.active)}>
        {WellContent}
      </Link>
    );
  } else {
    return <div className={classNames(style.root, { [style.includeBoxShadow]: includeBoxShadow })}>{WellContent}</div>;
  }
}
