import { type Driver } from 'driver.js';

import { DiscButton } from '@/components/disc-button/disc-button';
import LifeRing from '@/icons/fa/regular/life-ring';

import styles from './support-button.module.css';

interface SupportButtonProps {
  driver: Driver;
}

export function SupportButton(props: SupportButtonProps) {
  const { driver } = props;
  return (
    <div className={styles.root} data-testid="support-button">
      <DiscButton icon={LifeRing} onClick={() => driver.drive()} />
    </div>
  );
}
