import style from './graph-simple-donut.module.css';

interface Props {
  className?: string;
  percent: number;
  label: string;
}

export function GraphSimpleDonut(props: Props) {
  const { label, percent } = props;

  const radius = 29;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference * (1 - percent);

  return (
    <div className={style.root}>
      <svg width="64" height="64" viewBox="0 0 64 64">
        <circle r="29" cx="32" cy="32" className={style.background} strokeLinecap="round" strokeWidth="6" />
        <circle
          r={radius}
          cx="32"
          cy="32"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          className={style.foreground}
          transform="rotate(-90 32 32)"
          strokeLinecap="round"
          strokeWidth="6"
        />
      </svg>

      <div className={style.center}>{label}</div>
    </div>
  );
}
