import { Browser } from '@capacitor/browser';

import { Card } from '@/components/card/card';
import { isMobile, mailAdmin, privacyUrl, termsUrl, version } from '@/config';
import { Button } from '@/components/button/button';

import styles from './account-contact.module.css';

export function AccountContact() {
  const openTerms = async () => {
    await Browser.open({ url: `${termsUrl}?chromeless=true`, presentationStyle: 'popover' });
  };
  const openPrivacy = async () => {
    await Browser.open({ url: `${privacyUrl}?chromeless=true`, presentationStyle: 'popover' });
  };

  return (
    <Card>
      <div className={styles.content}>
        <p>
          If you have any issues or questions please contact us at <a href={`mailto:${mailAdmin}`}>{mailAdmin}</a>
        </p>

        <h2>Important links</h2>
        {!isMobile ? (
          <>
            <Button asAnchor fullWidth category="secondary" href={termsUrl}>
              Show Terms & Conditions
            </Button>
            <Button asAnchor fullWidth category="secondary" href={privacyUrl}>
              Show Privacy Policy
            </Button>
          </>
        ) : (
          <>
            <Button fullWidth category="secondary" onClick={openTerms}>
              Terms of Use
            </Button>
            <Button fullWidth category="secondary" onClick={openPrivacy}>
              Privacy Policy
            </Button>
          </>
        )}

        <p>App version: {version}</p>
      </div>
    </Card>
  );
}
