import { useEffect, useRef } from 'react';

import { Label } from '@/components/label/label';
import { Input } from '@/components/input/input';

import style from './clock.module.css';

type ClockProps = {
  hour: number;
  minute: number;
};

export function Clock(props: ClockProps) {
  const { hour, minute } = props;
  const refHourHand = useRef<HTMLDivElement>(null);
  const refMinuteHand = useRef<HTMLDivElement>(null);
  const refText = useRef<HTMLInputElement>(null);
  const currentHourRotation = useRef(0);
  const currentMinuteRotation = useRef(0);

  useEffect(() => {
    if (!refHourHand.current || !refMinuteHand.current || !refText.current) {
      return;
    }

    // https://en.wikipedia.org/wiki/Clock_angle_problem
    // prettier-ignore
    let hourRotation = 360 + (0.5 * (60 * hour + minute));
    // prettier-ignore
    let minuteRotation = 360 + (minute * 6);

    if (currentHourRotation.current > hourRotation) {
      hourRotation += Math.floor(currentHourRotation.current / 360) * 360;
    }
    if (currentMinuteRotation.current > minuteRotation) {
      minuteRotation += Math.floor(currentMinuteRotation.current / 360) * 360;
    }

    currentHourRotation.current = hourRotation;
    currentMinuteRotation.current = minuteRotation;

    refHourHand.current.style.transform = `rotate(${hourRotation}deg)`;
    refMinuteHand.current.style.transform = `rotate(${minuteRotation}deg)`;
  }, [hour, minute]);

  const minutes = minute > 9 ? `${minute}` : `0${minute}`;
  return (
    <div className={style.clock}>
      <div className={style.face}>
        {[...Array(60)].map((_, i) => (
          <div key={i} className={style.increment} style={{ transform: `rotate(${i * 6}deg)` }} />
        ))}
        <div ref={refMinuteHand} className={style.minuteHand} />
        <div ref={refHourHand} className={style.hourHand} />
      </div>
      <Label text="Time">
        <Input ref={refText} disabled variant="large" value={`${hour}:${minutes}`} />
      </Label>
    </div>
  );
}
