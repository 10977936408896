import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { Feedback } from '@/components/feedback/feedback';
import { Form } from '@/components/form/form';
import { get, recordResponse } from '@/utilities/api';
import { getCorrectMessage, getPreferredMessage, getIncorrectMessage } from '@/content';
import { HeroCard } from '@/components/hero-card/hero-card';
import { Highlight, Info, Key } from '@/components/info/info';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { Loader } from '@/components/loader/loader';
import { logger } from '@/utilities/logger';
import { randBetween } from '@/utilities/util';
import { Stack } from '@/components/stack/stack';
import { useKanaValue } from '@/hooks/use-kana-value';

import { generateAnswers } from './generate-answers';
import { Clock } from './clock/clock';
import styles from './time-practice.module.css';

type Status = 'correct' | 'acceptable' | 'incorrect';
type Submission = {
  status: Status;
  feedback: string;
};

type Config = {
  sysId: string;
  type: string;
};

const randomHour = () => randBetween(1, 12);
const randomMinute = () => randBetween(0, 59);
const log = logger('features/time-practice');

export default function TimePractice() {
  const [current, setCurrent] = useState<number>(0);
  const [hour, setHour] = useState<number>(() => randomHour());
  const [submissionCollection, setSubmissionCollection] = useState<Array<Submission>>([]);
  const [minute, setMinute] = useState<number>(() => randomMinute());
  const [value, setValue] = useKanaValue('');
  const [isCurrentComplete, setIsCurrentComplete] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isLoading, data } = useQuery(['time'], () => get<Config>('interaction/config/time'));
  const { acceptableAnswers, preferredAnswers } = generateAnswers(hour, minute);

  const check = () => {
    const isAcceptable = acceptableAnswers.includes(value);
    const isPreferred = preferredAnswers.includes(value);

    const correctAnswer = preferredAnswers[0];

    let feedback = '';
    let status: Status;
    let score;

    if (isPreferred) {
      status = 'correct';
      score = 5;
      feedback = getCorrectMessage();
    } else if (isAcceptable) {
      status = 'acceptable';
      score = 3;
      feedback = getPreferredMessage(value, correctAnswer);
    } else {
      status = 'incorrect';
      score = 1;
      feedback = getIncorrectMessage(correctAnswer);
    }

    setSubmissionCollection((s) => [...s, { status, feedback }]);
    setIsCurrentComplete(true);
    if (data) {
      recordResponse(data.type, data.sysId, score, { correctAnswer, submittedAnswer: value });
    }
  };

  const next = () => {
    setHour(randBetween(1, 12));
    setMinute(randBetween(0, 59));
    setCurrent((current) => current + 1);
    setValue('');
    setIsCurrentComplete(false);
    inputRef.current?.focus();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  log({ acceptableAnswers, preferredAnswers });

  return (
    <HeroCard title="What's the time?" size="small" breadcrumbs={[{ label: 'Practice', href: '/practice' }]}>
      <div className={styles.root}>
        <Card>
          {isLoading && <Loader />}

          <Stack gap="space-6">
            <Info>
              Type the displayed time in <Highlight>hiragana</Highlight>
              <br />
              <br />
              Bonus shortcut: Use <Key>ENTER</Key> to check answer and move to the next item
            </Info>

            <Clock hour={hour} minute={minute} />

            <Stack>
              <Form onSubmit={() => (isCurrentComplete ? next() : check())}>
                <Label text="Your answer">
                  <Input
                    autoFocus
                    onChange={setValue}
                    readOnly={isCurrentComplete}
                    ref={inputRef}
                    tabIndex={1}
                    value={value}
                    variant="large"
                  />
                </Label>

                <ActionBar variant="end">
                  <Button large type="submit" tabIndex={-1}>
                    {isCurrentComplete ? 'Next' : 'Check'}
                  </Button>
                </ActionBar>

                {isCurrentComplete ? (
                  <Feedback
                    type={submissionCollection[current].status}
                    message={submissionCollection[current].feedback}
                  />
                ) : null}
              </Form>
            </Stack>
          </Stack>
        </Card>
      </div>
    </HeroCard>
  );
}
