import LogoMark from '@/icons/logo-mark';

import { Button } from '@/components/button/button';
import { Links } from '@/utilities/api';

import { Panel } from '../panel/panel';
import styles from '../panel/panel.module.css';

type StripeProps = {
  links: Links;
};

export function Stripe(props: StripeProps) {
  const { links } = props;
  return (
    <Panel
      heading="You are subscribed - thank you!"
      subHeading={links.status[0] === 'NONE' ? null : links.status[1]}
      footer="Your subscription is securely handled by Stripe"
      icon={<LogoMark className={styles.headingIcon} />}
    >
      <Button category="secondary" fullWidth href={links.portalUrl ?? ''} asAnchor>
        Manage subscription
      </Button>
    </Panel>
  );
}
