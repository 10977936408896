import classNames from 'classnames';

import { IconProps } from '@/icons/icon-props';

import cn from './chip.module.css';

export type ChipType =
  // General purpose
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'disabled'
  // For levels
  | 'grey'
  | 'orange'
  | 'red'
  | 'purple'
  | 'green'
  | 'blue'
  | 'pink';

type ChipProps = {
  children: string;
  icon?: (props: IconProps) => JSX.Element;
  hasMarker?: boolean;
  type: ChipType;
};

export function Chip(props: ChipProps) {
  const { children, icon: Icon, type, hasMarker = false } = props;

  return (
    <div className={classNames(cn.root, { [cn.hasIcon]: Icon || hasMarker }, cn[type])}>
      {hasMarker && <span className={cn.marker} />}
      {Icon && <Icon className={cn.icon} />}
      {children}
    </div>
  );
}
