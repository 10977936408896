import { every, includes } from 'lodash';
import { useState, useEffect } from 'react';
import { shuffle } from 'lodash';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Feedback } from '@/components/feedback/feedback';
import { getCorrectMessage, getIncorrectMessage } from '@/content';
import { Info } from '@/components/info/info';
import { parseContent } from '@/components/text/text';
import { Audio } from '@/components/audio/audio';
import ImageLoader from '@/components/image-loader/image-loader';
import { WordOrder as WordOrderType } from '@/utilities/api';

import { constructAnswer } from './construct-answer';
import { splitSentence } from './split-sentence';
import Heading from './heading/heading';
import { constructResponse } from './construct-response';
import style from './word-order.module.css';

type WordOrderProps = {
  onComplete: (type: string, sysId: string, score: number, meta: any) => void;
  sysId: string;
  active?: boolean;
  config: WordOrderType['config'];
};

export function WordOrder(props: WordOrderProps) {
  const { active = true, sysId, config, onComplete } = props;
  const { audio, image, question, answers, imageConfig } = config;

  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
  const [isComplete, setIsComplete] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState<Array<number>>([]);

  // A correct result will be 0 in spot 0, 1 in spot 1, 2 in spot 2, and so on
  // Must also equal the total number! can't just pick 0 in 0 and be correct :|
  const isSuccessful = every(selectedIds, (key, index) => key === index) && selectedIds.length === answers.length;
  const questionParts = splitSentence(question);
  const constructedAnswer = constructAnswer(questionParts, answers);

  useEffect(() => {
    setShuffledAnswers(shuffle(answers.map((_, index) => index)));
  }, [answers]);

  function addAnswer(id: number) {
    setSelectedIds([...selectedIds, id]);
  }

  function reset() {
    setSelectedIds([]);
  }

  function check() {
    const selected = selectedIds.map((id) => answers[id]);
    const response = constructResponse(questionParts, answers, selectedIds);
    setIsComplete(true);
    onComplete('word-order', sysId, isSuccessful ? 5 : 1, { selected, response });
  }

  return (
    <div className={style.root}>
      {image && <ImageLoader size={imageConfig.backgroundSize} src={image} />}
      {!isComplete && <Info>Click the missing words to place them in the correct order</Info>}
      <Heading questionParts={questionParts} answers={answers} selectedIds={selectedIds} />
      <div className={style.content}>
        {shuffledAnswers.map((id) => (
          <div className={style.answer} key={id}>
            <Button
              category="secondary"
              onClick={() => addAnswer(id)}
              enabled={!includes(selectedIds, id) && !isComplete && active}
            >
              <span dangerouslySetInnerHTML={{ __html: parseContent(answers[id]) }} />
            </Button>
          </div>
        ))}
      </div>

      {isComplete ? (
        <Feedback
          type={isSuccessful ? 'correct' : 'incorrect'}
          message={isSuccessful ? getCorrectMessage() : getIncorrectMessage(constructedAnswer)}
        />
      ) : (
        <ActionBar variant="center">
          <Button enabled={!isComplete && selectedIds.length > 0 && active} category="secondary" onClick={reset}>
            Reset
          </Button>
          <Button enabled={selectedIds.length > 0 && active} onClick={check}>
            Check
          </Button>
        </ActionBar>
      )}
      {audio && (
        <div className={style.audio}>
          <Audio disabled={!isComplete} url={audio} />
        </div>
      )}
    </div>
  );
}
