import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { Modal } from '@/components/modal/modal';
import { MultipleChoice } from '@/components/multiple-choice/multiple-choice';
import { MultipleChoice as MultipleChoiceType, recordResponse, WordOrder as WordOrderTtype } from '@/utilities/api';
import { Streak } from '@/components/streak/streak';
import { WordOrder } from '@/components/word-order/word-order';

import cn from './interaction-block.module.css';

type InteractionBlockProps = {
  name: string;
  interaction: MultipleChoiceType | WordOrderTtype;
  onComplete?: () => void;
};

export function InteractionBlock(props: InteractionBlockProps) {
  const { name, interaction, onComplete: _onComplete } = props;

  const onComplete = async (type: string, sysId: string, score: number, meta: any) => {
    await recordResponse(type, sysId, score, meta);

    fetch('https://api.eventscout.io/api/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source_id: 'wl',
        type: `${type}-quiz-answer`,
        payload: {
          sysId,
          score,
          ...meta,
        },
      }),
    });

    if (_onComplete) {
      _onComplete();
    }
  };

  return (
    <>
      <Card padding="small">
        <div className={cn.root}>
          <span className={cn.name}>{name}</span>

          <Streak count={interaction.streak} />
          <div className={cn.date}>
            <span>Due:</span>
            <span>{interaction.due}</span>
          </div>

          <div className={cn.button}>
            <Modal
              heading={name}
              trigger={
                <Button category="secondary" testId={`take-quiz-${interaction.sysId}`}>
                  Take quiz
                </Button>
              }
            >
              {interaction.type === 'multiple-choice' && (
                <MultipleChoice onComplete={onComplete} sysId={interaction.sysId} config={interaction.config} />
              )}
              {interaction.type === 'word-order' && (
                <WordOrder onComplete={onComplete} sysId={interaction.sysId} config={interaction.config} />
              )}
            </Modal>
          </div>
        </div>
      </Card>
    </>
  );
}
