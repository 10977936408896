import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import style from './button-group.module.css';

interface IProps {
  items: Array<{ value: string; label: string }>;
  onChange: (value: string) => void;
  value: string;
  testId?: string;
}

const ButtonGroup = (props: IProps) => {
  const { items, value, onChange, testId } = props;
  const buttons = useRef<{ [key: string]: HTMLButtonElement }>({});
  const slider = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = buttons.current[value];
    if (element && slider.current) {
      slider.current.style.width = `${element.clientWidth}px`;
      slider.current.style.left = `${element.offsetLeft}px`;
    }
  }, [value]);

  return (
    <div className={style.root} data-testid={testId}>
      {items.map((item) => (
        <button
          ref={(element) => {
            if (element) {
              buttons.current[item.value] = element;
            }
          }}
          className={classNames(style.button, { [style.active]: item.value === value })}
          key={item.value.toString()}
          data-testid={`${testId}-${item.value}`}
          onClick={(e) => {
            e.preventDefault();
            onChange(item.value);
          }}
        >
          {item.label}
        </button>
      ))}
      <div className={style.slider} ref={slider} />
    </div>
  );
};

export default ButtonGroup;
