import classNames from 'classnames';

import { Text } from '@/components/text/text';

import { delim } from '../split-sentence';
import style from './heading.module.css';

interface TProps {
  answers: string[];
  questionParts: string[];
  selectedIds: number[];
}

export default function Heading(props: TProps) {
  const { answers, questionParts, selectedIds } = props;
  let delimCount = 0;
  const elements = questionParts.map((text, index) => {
    let element = null;

    if (text === delim) {
      if (delimCount < selectedIds.length) {
        element = (
          <span key={index} className={classNames(style.spot, style.selected)}>
            <Text element="span" brand={true} noStyle>
              {answers[selectedIds[delimCount]]}
            </Text>
          </span>
        );
      } else {
        element = (
          <span key={index} className={style.spot}>
            &nbsp;
          </span>
        );
      }
      delimCount++;
    } else {
      element = (
        <Text element="span" className={style.text} brand={true} key={index} noStyle>
          {text}
        </Text>
      );
    }

    return element;
  });

  return <h2 className={style.root}>{elements}</h2>;
}
