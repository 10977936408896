import toast from 'react-hot-toast';

type Type = 'info' | 'success' | 'error';

const FLASH_KEY = 'flash';

export function redirectWithFlash(route: string, message: string, type: Type = 'info') {
  window.localStorage.setItem(FLASH_KEY, JSON.stringify({ message, type }));
  window.location.assign(route);
}

export function flash(message: string, type: Type = 'info') {
  switch (type) {
    case 'info': {
      toast(message);
      break;
    }
    case 'success': {
      toast.success(message);
      break;
    }
    case 'error': {
      toast.error(message);
      break;
    }
  }
}

if (typeof window !== 'undefined') {
  const messagesFromCookie = window.localStorage.getItem(FLASH_KEY);
  if (messagesFromCookie) {
    window.localStorage.removeItem(FLASH_KEY);

    const { message, type } = JSON.parse(messagesFromCookie);
    flash(message, type);
  }

  (window as any).flash = flash;
}
