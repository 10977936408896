import { useEffect, useState } from 'react';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Card } from '@/components/card/card';
import { get } from '@/utilities/api';
import { HeroCard } from '@/components/hero-card/hero-card';
import { Loader } from '@/components/loader/loader';
import { StretchButton } from '@/components/stretch-button/stretch-button';
import { FilterBar } from '@/components/filter-bar/filter-bar';

import { EmptyState } from './empty-state/empty-state';
import { Results } from './results/results';
import styles from './vocabulary.module.css';

export type ValuePair = {
  label: string;
  value: string;
};

export type Level = {
  name: string;
  sysId: string;
};

export type Vocabulary = {
  id: string;
  kanji: string;
  english: string;
  exampleSentence: string;
  kana: string;
  type: string;
  tags: Array<{ value: string; label: string }>;
};

export type SearchResponse = {
  items: Array<Vocabulary>;
  meta: {
    hasMore: boolean;
    cursor: string;
  };
};

type MetaResponse = {
  tags: Array<{
    value: string;
    label: string;
  }>;
  types: Array<{
    value: string;
    label: string;
  }>;
  levels: Array<{
    name: string;
    sysId: string;
  }>;
};

export default function Vocabulary() {
  const [allLevels, setAllLevels] = useState<Array<ValuePair>>([]);
  const [allTags, setAllTags] = useState<Array<ValuePair>>([]);
  const [level, setLevel] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tag, setTag] = useState<string>('');
  const [term, setTerm] = useState<string>('');
  const [items, setItems] = useState<SearchResponse['items']>([]);
  const [meta, setMeta] = useState<SearchResponse['meta'] | null>(null);

  const search = async (cursor: string | null, term: string, tag: string, level: string) => {
    const params = {
      cursor,
      term: term.trim(),
      tag: tag,
      level: level,
    };
    setIsLoading(true);
    const data = await get<SearchResponse>('vocabulary/search', { params });
    setItems((existingItems) => (cursor !== null ? [...existingItems, ...data.items] : data.items));
    setMeta(data.meta);
    setIsLoading(false);
  };

  useEffect(() => {
    search(null, '', '', '');
  }, []);

  useEffect(() => {
    get<MetaResponse>('vocabulary/meta').then((data) => {
      setAllLevels([...data.levels.map(({ name, sysId }) => ({ label: name, value: sysId }))]);
      setAllTags([
        ...data.tags.map(({ label, value }) => ({ label, value: `tag__${value}` })),
        ...data.types.map(({ label, value }) => ({ label, value: `type__${value}` })),
      ]);
    });
  }, []);

  const showResults = items.length > 0;
  const showEmptyState = !isLoading && items.length === 0;
  const showMoreButton = items.length > 0 && meta?.hasMore;
  const isReady = !isLoading;

  return (
    <HeroCard title="Vocabulary" size="wide" breadcrumbs={[{ label: 'Resources', href: '/resources' }]}>
      <div className={styles.root}>
        <Card>
          <FilterBar
            showLevels={true}
            showBookmarked={false}
            showSearchButton={true}
            level={level}
            setLevel={setLevel}
            setTag={setTag}
            setTerm={setTerm}
            tag={tag}
            allTags={allTags}
            allLevels={allLevels}
            term={term}
            onSubmit={() => search(null, term, tag, level)}
          />
        </Card>
        {showEmptyState && (
          <Card>
            <EmptyState />
          </Card>
        )}
        {!isReady ? (
          <Loader />
        ) : (
          <>
            {showResults && (
              <div className={styles.results}>
                <Results items={items} />
              </div>
            )}
            {showMoreButton && (
              <div className={styles.actionBar}>
                <ActionBar variant="center">
                  <StretchButton loading={isLoading} onClick={() => search(meta?.cursor ?? '', term, tag, level)}>
                    More
                  </StretchButton>
                </ActionBar>
              </div>
            )}
          </>
        )}
      </div>
    </HeroCard>
  );
}
