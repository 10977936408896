import { useState } from 'react';
import * as Wanakana from 'wanakana';

export const useKanaValue = (inputValue: string) => {
  const [value, setValue] = useState(inputValue);

  const _setValue = (nextValue: string) => {
    setValue(Wanakana.toKana(nextValue, { IMEMode: true }));
  };

  return [value, _setValue] as const;
};
