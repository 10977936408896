import classNames from 'classnames';
import { useRef } from 'react';

import CheckCircle from '@/icons/fa/light/check-circle';
import useIntersectionObserver from '@/hooks/use-intersection-observer';

import cn from './streak.module.css';

type StreakProps = {
  count: number;
};

export function Streak(props: StreakProps) {
  const { count } = props;
  const ref = useRef(null);

  const entry = useIntersectionObserver(ref, {});

  return (
    <div className={cn.root} ref={ref}>
      {Array.from({ length: 5 }).map((_item, i) =>
        i + 1 > count ? (
          <div key={i} className={classNames(cn.dot, cn.na, entry?.isIntersecting ? cn.visible : null)} />
        ) : (
          <CheckCircle key={i} className={classNames(cn.dot, cn.pass, entry?.isIntersecting ? cn.visible : null)} />
        )
      )}
    </div>
  );
}
