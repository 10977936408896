import classNames from 'classnames';
import { ReactNode } from 'react';

import style from './card.module.css';

interface CardProps {
  canHover?: boolean;
  children?: ReactNode;
  isDisabled?: boolean;
  isFullHeight?: boolean;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  padding?: 'none' | 'small' | 'normal';
}

export function Card(props: CardProps) {
  const {
    children,
    canHover = false,
    padding = 'normal',
    isDisabled = false,
    isFullHeight = false,
    onMouseOver,
    onMouseOut,
  } = props;

  return (
    <div
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={classNames(style.root, {
        [style.noPadding]: padding === 'none',
        [style.smallPadding]: padding === 'small',
        [style.normalPadding]: padding === 'normal',
        [style.isDisabled]: isDisabled,
        [style.isFullHeight]: isFullHeight,
        [style.canHover]: canHover && !isDisabled,
      })}
    >
      {children}
    </div>
  );
}
