import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import style from './nav-item.module.css';

export type TopLevelNavItems = 'dashboard' | 'lessons' | 'practice' | 'profile' | 'stories' | 'resources' | 'discuss';

type NavItemProps = {
  children: ReactNode;
  href: string;
  id: TopLevelNavItems;
  target?: string;
};

const matchers: Record<TopLevelNavItems, (pathname: string) => boolean> = {
  dashboard: (pathname: string) => pathname === '/',
  resources: (pathname: string) => pathname.startsWith('/resources'),
  lessons: (pathname: string) => pathname.startsWith('/lessons'),
  practice: (pathname: string) => pathname.startsWith('/practice'),
  profile: (pathname: string) => pathname.startsWith('/account'),
  stories: (pathname: string) => pathname.startsWith('/stories'),
  discuss: () => false,
};

export function NavItem(props: NavItemProps) {
  const { children, id, href, target } = props;
  const { pathname } = useLocation();
  const isActive = matchers[id](pathname);
  const className = classNames(style.root, { [style.isActive]: isActive });

  if (target && href) {
    return (
      <a className={className} data-testid={`app-nav-${id}`} href={href} target={target}>
        {children}
      </a>
    );
  } else if (href) {
    return (
      <Link className={className} data-testid={`app-nav-${id}`} to={href}>
        {children}
      </Link>
    );
  }
}
