import { ReactNode } from 'react';

import style from './form-info.module.css';

type FormInfoProps = {
  children?: ReactNode;
};

export function FormInfo(props: FormInfoProps) {
  const { children } = props;
  return <div className={style.root}>{children}</div>;
}
