import { match } from 'ts-pattern';
import { Link } from 'react-router-dom';

import { Chip } from '@/components/chip/chip';
import { FormInfo } from '../form-info/form-info';
import { getLessonPath, getStoryPath } from '@/utilities/paths';
import { Input } from '../input/input';
import { Modal } from '../modal/modal';
import { Tags } from '@/components/tags/tags';
import { Text } from '@/components/text/text';
import Award from '@/icons/fa/light/award';
import Book from '@/icons/fa/light/book';
import BookAlt from '@/icons/fa/light/book-alt';
import Check from '@/icons/fa/light/check';
import SearchIcon from '@/icons/fa/light/search';
import Tag from '@/icons/fa/light/tag';
import { type StoryResult, type LessonResult } from '@/utilities/api';

import styles from './search.module.css';

interface SearchProps {
  term: string;
  setTerm: (term: string) => void;
  data: Array<StoryResult | LessonResult> | undefined;
}

function StoryResult(item: StoryResult) {
  const { title, englishTitle, tags, isRead, isLocked, sysId, image } = item;
  return (
    <li>
      <Link className={styles.result} to={getStoryPath(sysId)}>
        <div className={styles.content}>
          <Text className={styles.title}>{title}</Text>
          <p className={styles.byline}>{englishTitle}</p>
          <div className={styles.tags}>
            <Chip type="info" icon={Book}>
              Story
            </Chip>
            {isLocked && (
              <Chip icon={Award} type="error">
                Premium only
              </Chip>
            )}
            <Chip type={isRead && !isLocked ? 'success' : 'disabled'} icon={isRead ? Check : undefined}>
              {isRead ? 'Read' : 'Unread'}
            </Chip>
            <Tags idKey="value" icon={Tag} items={tags} />
          </div>
        </div>
        <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
      </Link>
    </li>
  );
}

function LessonResult(item: LessonResult) {
  const { title, tags, isLocked, isRead, sysId } = item;
  return (
    <li>
      <Link className={styles.result} to={getLessonPath(sysId)}>
        <div className={styles.content}>
          <Text className={styles.title}>{title}</Text>
          <div className={styles.tags}>
            <Chip type="info" icon={BookAlt}>
              Lesson
            </Chip>
            {isLocked && (
              <Chip icon={Award} type="error">
                Premium only
              </Chip>
            )}
            <Chip type={isRead && !isLocked ? 'success' : 'disabled'} icon={isRead ? Check : undefined}>
              {isRead ? 'Read' : 'Unread'}
            </Chip>
            <Tags idKey="value" icon={Tag} items={tags} />
          </div>
        </div>
      </Link>
    </li>
  );
}

export function Search(props: SearchProps) {
  const { term, setTerm, data } = props;

  return (
    <Modal
      heading="Search"
      shortcut="/"
      trigger={
        <div className={styles.searchBar} tabIndex={0}>
          <SearchIcon className={styles.searchBarIcon} />
          Type <span>/</span> to search
        </div>
      }
    >
      <div className={styles.root}>
        <div className={styles.field}>
          <Input autoFocus placeholder="Search" onChange={setTerm} value={term} icon={SearchIcon} />
          {data !== undefined && <FormInfo>{data?.length} results</FormInfo>}
        </div>
        <ul className={styles.results}>
          {data?.map((item) =>
            match(item)
              .with({ type: 'story' }, (item) => <StoryResult key={`story-${item.sysId}`} {...item} />)
              .with({ type: 'lesson' }, (item) => <LessonResult key={`lesson-${item.sysId}`} {...item} />)
              .exhaustive()
          )}
        </ul>
      </div>
    </Modal>
  );
}
