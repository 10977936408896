import { ReactNode } from 'react';

import style from './h2.module.css';

type H2Props = {
  children?: ReactNode;
};

export function H2(props: H2Props) {
  const { children } = props;
  return <h2 className={style.root}>{children}</h2>;
}
