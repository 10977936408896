import { ReactNode } from 'react';
import classnames from 'classnames';

import style from './form.module.css';

type FormProps = {
  children?: ReactNode;
  heading?: string;
  layout?: 'horizontal' | 'vertical';
  onSubmit?: () => void;
};

export function Form(props: FormProps) {
  const { children, heading, layout = 'vertical', onSubmit } = props;

  return (
    <form
      className={classnames(style.root, style[layout])}
      role="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}
    >
      {heading && <div className={style.heading}>{heading}</div>}
      <div className={style.content}>{children}</div>
    </form>
  );
}
