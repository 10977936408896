import classNames from 'classnames';
import { useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/themes/light.css';

import { parseContent, withoutFurigana } from '@/components/text/text';

import { type Mode } from '../story';
import { SimpleLink } from '@/components/simple-link/simple-link';
import { getLessonPath } from '@/utilities/paths';
import { type LineType } from '@/utilities/api';
import styles from './line.module.css';

type LineProps = {
  line: LineType;
  mode: Mode;
  isHighlighted: boolean;
};

type HintProps = {
  text: string;
  label: string;
  lesson?: { sysId: string } | null;
};

type HintInput = {
  text: string;
  keyword: string;
  lesson?: { sysId: string } | null;
};

const Hint = (props: HintProps) => {
  const [visible, setVisible] = useState(false);
  const { text, label, lesson } = props;
  return (
    <Tippy
      content={
        <>
          <div className={styles.content}>{withoutFurigana(text)}</div>
          {lesson && <SimpleLink to={getLessonPath(lesson.sysId)}>Open related lesson</SimpleLink>}
        </>
      }
      animation="scale"
      theme="light"
      placement="top"
      interactive
      className={styles.tippy}
      visible={visible}
      onClickOutside={() => setVisible(false)}
    >
      <span className={styles.underline} onClick={() => setVisible(true)} data-testid="line-hint">
        {label}
      </span>
    </Tippy>
  );
};

const renderComponents = (text: string, hints: Array<HintInput>) => {
  const elements = [];
  let remainingText = text;

  (hints ?? []).forEach((hint) => {
    const { keyword, text, lesson } = hint;
    const index = remainingText.indexOf(keyword);

    if (index !== -1) {
      const preKeywordText = remainingText.slice(0, index);
      if (preKeywordText) {
        elements.push(withoutFurigana(preKeywordText));
      }

      const matchedKeyword = remainingText.slice(index, index + keyword.length);
      elements.push(<Hint key={keyword} text={text} label={withoutFurigana(matchedKeyword)} lesson={lesson} />);

      remainingText = remainingText.slice(index + keyword.length);
    }
  });

  if (remainingText) {
    elements.push(withoutFurigana(remainingText));
  }

  return elements;
};

function processText(line: LineType, mode: Mode) {
  const { jp } = line;

  switch (mode) {
    case 'none':
    case 'english':
      return <div dangerouslySetInnerHTML={{ __html: withoutFurigana(jp) }} />;

    case 'kana':
      return <div dangerouslySetInnerHTML={{ __html: parseContent(jp) }} />;

    case 'vocabulary':
      return renderComponents(jp, line.vocabulary ?? []);

    case 'grammar':
      return renderComponents(jp, line.grammar ?? []);
  }
}

export function Line(props: LineProps) {
  const { line, mode, isHighlighted } = props;

  return (
    <div className={classNames(styles.root, { [styles.isHighlighted]: isHighlighted })}>
      <div className={styles.text}>{processText(line, mode)}</div>

      {mode === 'english' && (
        <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: parseContent(line.en) }} />
      )}
    </div>
  );
}
