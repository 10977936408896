import classNames from 'classnames';

import style from './mini-triad.module.css';

type MiniTriadProps = {
  className?: string;
  variant?: 'primary' | 'secondary';
};

export function MiniTriad(props: MiniTriadProps) {
  const { variant = 'primary', className } = props;
  return <i className={classNames(style.root, style[variant], className)} />;
}
