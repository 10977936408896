import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSearch } from '@/utilities/api';

import {
  getAccountPath,
  getDiscussPath,
  getLessonsPath,
  getMobileAccountPath,
  getPracticePath,
  getResourcesPath,
  getStoriesPath,
  getWelcomePath,
} from '@/utilities/paths';
import { NavItem } from './nav-item/nav-item';
import { Search } from '../search/seach';
import { useDebounce } from '@/hooks/use-debounce';
import Star from '@/icons/fa/solid/star';

import style from './app-nav.module.css';

type AppNavProps = {
  isPremium: boolean;
};

export function AppNav(props: AppNavProps) {
  const { isPremium } = props;
  const { pathname } = useLocation();
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term);
  const { data } = useSearch(debouncedTerm);

  useEffect(() => {
    setTerm('');
  }, [pathname]);

  return (
    <nav className={style.root} data-testid="app-nav">
      <div className={style.left}>
        <Link to="/" className={style.brand}>
          <img alt="ImaWakatta" src="/logo-mark.svg" className={style.logo} />
        </Link>
        <div className={style.search}>
          <Search term={term} setTerm={setTerm} data={data} />
        </div>
      </div>
      <ul className={style.right}>
        <li>
          <NavItem id="dashboard" href={getWelcomePath()}>
            Dashboard
          </NavItem>
        </li>
        <li>
          <NavItem id="stories" href={getStoriesPath()}>
            Stories
          </NavItem>
        </li>
        <li>
          <NavItem id="lessons" href={getLessonsPath()}>
            Lessons
          </NavItem>
        </li>
        <li>
          <NavItem id="practice" href={getPracticePath()}>
            Practice
          </NavItem>
        </li>
        <li>
          <NavItem id="resources" href={getResourcesPath()}>
            Resources
          </NavItem>
        </li>
        <li>
          <NavItem id="discuss" href={getDiscussPath()} target="_blank">
            Discord
          </NavItem>
        </li>
        <li>
          <NavItem id="profile" href={getAccountPath()}>
            Account
            {isPremium && (
              <div className={style.isPremium}>
                <Star /> PREMIUM
              </div>
            )}
          </NavItem>
        </li>
      </ul>
      <ul className={style.rightMobile}>
        <li>
          <NavItem id="profile" href={getMobileAccountPath()}>
            Account
            {isPremium && (
              <div className={style.isPremium}>
                <Star /> PREMIUM
              </div>
            )}
          </NavItem>
        </li>
      </ul>
    </nav>
  );
}
