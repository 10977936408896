import { driver } from 'driver.js';
import { useEffect, useRef } from 'react';

import 'driver.js/dist/driver.css';
import '@/styles/driver.css';

import { useQueryMe, useUpdateTutorials } from '@/utilities/api';
import { storiesSupport, welcomeSupport } from '@/support';
import { useMediaQuery } from '@/hooks/use-media-query';

const supportSteps = {
  welcome: welcomeSupport,
  stories: storiesSupport,
} as const;

type Page = keyof typeof supportSteps;

export function useDriver(page: Page) {
  const mutation = useUpdateTutorials();
  const isDesktop = useMediaQuery('(min-width: 768px) and (hover: hover)');
  const { data } = useQueryMe();

  const driverObj = useRef(
    driver({
      onDestroyed: () => {
        mutation.mutate(page);
      },
      steps: supportSteps[page][isDesktop ? 'desktop' : 'mobile'],
    })
  );

  useEffect(() => {
    if (data && !data.tutorials.dismissed.includes(page)) {
      driverObj.current.drive();
    }
  }, [driverObj, data, page]);

  return driverObj.current;
}
