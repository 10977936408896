import { Text } from '@/components/text/text';
import { Streak } from '@/components/streak/streak';
import { H3 } from '@/components/h3/h3';

import cn from './streak-info.module.css';

export function StreakInfo() {
  return (
    <div className={cn.root}>
      <Text>
        <p>
          Spaced repetition is a fancy way for figuring out when you should retake a quiz. The more times you get a quiz
          correct, the further we push back the next due date.
        </p>
        <p>
          Each quiz has a <strong>"streak"</strong> and a <strong>"due date"</strong>.
        </p>
      </Text>

      <div className={cn.row}>
        <div className={cn.left}>
          <H3>A streak of 0 🙈</H3>
          <Text>You have never done the quiz, or, all recent attempts have been unsuccessful.</Text>
        </div>
        <div className={cn.right}>
          <Streak count={0} />
          <div className={cn.date}>
            <span>Due:</span>
            <span>Now</span>
          </div>
        </div>
      </div>

      <div className={cn.row}>
        <div className={cn.left}>
          <H3>A streak of 1 👌</H3>
          <Text>
            <p>
              You have passed the quiz 1 time in a row.
              <br />
              You should retake this quiz soon.
            </p>
          </Text>
        </div>
        <div className={cn.right}>
          <Streak count={1} />
          <div className={cn.date}>
            <span>Due:</span>
            <span>In 1 day</span>
          </div>
        </div>
      </div>

      <div className={cn.row}>
        <div className={cn.left}>
          <H3>A streak of 3 👏</H3>
          <Text>
            <p>
              You have passed the quiz 3 times in a row.
              <br />
              You should retake this quiz in the next week or so.
            </p>
          </Text>
        </div>
        <div className={cn.right}>
          <Streak count={3} />
          <div className={cn.date}>
            <span>Due:</span>
            <span>In 3 days</span>
          </div>
        </div>
      </div>

      <div className={cn.row}>
        <div className={cn.left}>
          <H3>A streak of 5 🎉</H3>
          <Text>
            <p>
              You have passed the quiz 5 times in a row. <br />
              This quiz is second nature, you don't need to do it again for 30 days
            </p>
          </Text>
        </div>
        <div className={cn.right}>
          <Streak count={5} />
          <div className={cn.date}>
            <span>Due:</span>
            <span>In 30 days</span>
          </div>
        </div>
      </div>

      <Text>
        <p>
          <strong>Note:</strong> We recommend you retake the quiz close to the due date. <br />
          You <em>could</em> retake it straight away to score a streak, but you'd only be cheating yourself 👹
        </p>
      </Text>
    </div>
  );
}
