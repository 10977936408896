import { MouseEventHandler, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import { MiniTriad } from '@/components/mini-triad/mini-triad';

import style from './stretch-button.module.css';

interface IProps {
  children?: ReactNode;
  className?: string;
  loading: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function StretchButton(props: IProps) {
  const { className, loading, children, onClick: _onClick, disabled = false } = props;

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!disabled && !loading && _onClick) {
      _onClick(e);
    }
  };

  return (
    <button
      className={classNames(style.root, className, {
        [style.loading]: loading,
        [style.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <span className={style.label}>{children}</span>
      <MiniTriad className={style.loader} />
    </button>
  );
}
