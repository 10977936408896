import { Button } from '@/components/button/button';
import { H1 } from '@/components/h1/h1';
import { LessonChips } from '@/components/lesson-chips/lesson-chips';
import { MiniTriad } from '@/components/mini-triad/mini-triad';
import { Modal } from '@/components/modal/modal';
import { Note } from '@/components/content-blocks/note/note';
import { Sentence } from '@/components/content-blocks/sentence/sentence';
import { SymbolInfo } from '@/components/symbol-info/symbol-info';
import { TextV2 } from '@/components/text/text';
import InfoCircle from '@/icons/fa/light/info-circle';
import Star from '@/icons/fa/solid/star';
import StarOutline from '@/icons/fa/light/star';
import VocabList from '@/components/content-blocks/vocab-list/vocab-list';

import cn from './lesson.module.css';

type LessonProps = {
  exampleSentences: Array<{ content: string; audios: Array<{ url: string }> }> | null;
  explanation: string;
  isBookmarked: boolean;
  isLocked: boolean;
  isRead: boolean;
  isSavingBookmark: boolean;
  meaning: string;
  note: string | null;
  onBookmark: () => void;
  rule: string | null;
  sysId: string;
  tags: Array<string>;
  vocabulary: string | null;
};

type StarButtonProps = {
  isSavingBookmark: boolean;
  isBookmarked: boolean;
  onBookmark: () => void;
};

function StarButton(props: StarButtonProps) {
  const { isBookmarked, isSavingBookmark, onBookmark } = props;
  if (isSavingBookmark) {
    return (
      <div className={cn.miniWrapper}>
        <MiniTriad variant="secondary" />
      </div>
    );
  } else if (isBookmarked) {
    return (
      <button onClick={onBookmark} className={cn.starButton} data-testid="lesson-star-button-on">
        <Star className={cn.starBookmarked} />
      </button>
    );
  } else {
    return (
      <button onClick={onBookmark} className={cn.starButton} data-testid="lesson-star-button-off">
        <StarOutline className={cn.star} />
      </button>
    );
  }
}

export function Lesson(props: LessonProps) {
  const {
    isBookmarked,
    onBookmark,
    isLocked,
    tags,
    meaning,
    explanation,
    rule,
    note,
    exampleSentences,
    vocabulary,
    isSavingBookmark,
  } = props;

  return (
    <div className={cn.root}>
      <div className={cn.toolbar}>
        <LessonChips isLocked={isLocked} tags={tags} />

        <StarButton isSavingBookmark={isSavingBookmark} isBookmarked={isBookmarked} onBookmark={onBookmark} />
      </div>

      {meaning && (
        <div className={cn.contentBlock} data-testid="lesson-meaning">
          <H1>Meaning</H1>
          <TextV2 text={meaning} />
        </div>
      )}

      {explanation && (
        <div className={cn.contentBlock} data-testid="lesson-explanation">
          <H1>Explanation</H1>
          <TextV2 text={explanation} />
        </div>
      )}

      {rule && (
        <div className={cn.contentBlock} data-testid="lesson-rule">
          <H1>Rule</H1>
          <Modal
            heading="List of symbols and notations"
            confirmLabel="Got it!"
            trigger={
              <Button small category="secondary">
                <InfoCircle className={cn.icon} /> Symbols & notations
              </Button>
            }
          >
            <SymbolInfo />
          </Modal>
          <TextV2 text={rule} />
        </div>
      )}

      {note && (
        <div data-testid="lesson-note">
          <Note body={note} title="Note" />
        </div>
      )}

      {exampleSentences && (
        <div className={cn.exampleSentences} data-testid="lesson-example-sentence">
          {exampleSentences.map((sentence, index) => (
            <Sentence key={index} number={(index + 1).toString()} audios={sentence.audios} body={sentence.content} />
          ))}
        </div>
      )}

      {vocabulary && (
        <div data-testid="lesson-vocabulary">
          <VocabList
            defaultOpen={true}
            rows={vocabulary.split('\n').map((item) => item.split('|')) as Array<[string, string]>}
          />
        </div>
      )}
    </div>
  );
}
