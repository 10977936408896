import classNames from 'classnames';
import { forwardRef } from 'react';

import { IconProps } from '@/icons/icon-props';

const noop = () => {};

import style from './disc-button.module.scss';

type DiscButtonProps = {
  category?: 'primary' | 'secondary';
  icon: (props: IconProps) => JSX.Element;
  className?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  tabIndex?: number;
  title?: string;
};

export const DiscButton = forwardRef<HTMLButtonElement, DiscButtonProps>(function DiscButton(props, ref) {
  const { category = 'primary', icon: Icon, className, disabled = false, onClick, title } = props;

  return (
    <button
      ref={ref}
      onClick={disabled ? noop : onClick}
      className={classNames(style.root, style[category], { [style.disabled]: disabled }, className)}
      type="button"
      title={title}
    >
      <Icon className={style.icon} />
    </button>
  );
});
