import { isProduction } from '@/config';

const exclude: Array<string> = ['utilities/timer'];

export function logger(module: string) {
  if (isProduction || exclude.includes(module)) {
    return () => {};
  }

  return (...data: any[]) => console.log(...data, { module });
}
