import classNames from 'classnames';

import styles from './page.module.css';
import { Button } from '@/components/button/button';
import ChevronLeft from '@/icons/fa/solid/chevron-left';

type PageProps = {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  onBack: () => void;
};

export function Page(props: PageProps) {
  const { children, title, isOpen, onBack } = props;

  return (
    <div className={classNames(styles.root, { [styles.open]: isOpen })}>
      <div className={styles.header}>
        <div className={styles.headerBack}>
          <Button small category="whiteLink" icon={ChevronLeft} onClick={() => onBack()}>
            Back
          </Button>
        </div>
        <h2 className={styles.headerText}>{title}</h2>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
