import classNames from 'classnames';
import { ReactNode } from 'react';

import style from './loader.module.css';

interface IProps {
  isGlobal?: boolean;
  message?: ReactNode | null;
}

export function Loader(props: IProps) {
  const { isGlobal = false, message = null } = props;

  return (
    <div className={classNames(style.root, { [style.isGlobal]: isGlobal })}>
      <div className={style.element} />
      {message !== null && <div className={style.message}>{message}</div>}
    </div>
  );
}
