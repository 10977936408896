import LogoMark from '@/icons/logo-mark';
import { Button } from '@/components/button/button';
import { appleCancelUrl } from '@/config';

import { Panel } from '../panel/panel';
import styles from '../panel/panel.module.css';

export function RevenueCat() {
  return (
    <Panel
      heading="You are subscribed via iTunes - thank you!"
      footer="Your subscription is securely handled by Apple."
      icon={<LogoMark className={styles.headingIcon} />}
    >
      <Button category="secondary" fullWidth href={appleCancelUrl} asAnchor target="_blank">
        Manage subscription in iTunes
      </Button>
    </Panel>
  );
}
