import { delim } from './split-sentence';

export function constructResponse(questionParts: string[], answers: string[], selectedIds: number[]) {
  let delimCount = 0;

  return questionParts
    .map((part) => {
      let chunk;
      if (part === delim) {
        chunk = '(' + answers[selectedIds[delimCount]] + ')';
        delimCount++;
      } else {
        chunk = part;
      }
      return chunk;
    })
    .join(' ');
}
