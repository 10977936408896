export const welcomeSupport = {
  mobile: [
    {
      element: '[data-testid="mobile-nav-stories"]',
      popover: {
        title: 'Stories',
        description: `Learn Japanese contextually through stories in Japanese and English. Access grammar explanations, translations and line by line audio highlighting. <img width="744" height="762" src="/assets/support/stories.jpg" />`,
      },
    },
    {
      element: '[data-testid="mobile-nav-lessons"]',
      popover: {
        title: 'Lessons',
        description: `Master Japanese grammar and vocabulary with our flexible lessons .<img width="671" height="552" src="/assets/support/lessons.jpg" />`,
      },
    },
    {
      element: '[data-testid="mobile-nav-practice"]',
      popover: {
        title: 'Practice',
        description: `Hone your hiragana, katakana, conjugation, and time-telling skills here. <img width="672" height="540" src="/assets/support/practice.jpg" />`,
      },
    },
    {
      element: '[data-testid="mobile-nav-resources"]',
      popover: {
        title: 'Resources',
        description: `Access quick references for Japanese hiragana, katakana, and vocabulary. <img width="685" height="546" src="/assets/support/resources.jpg" />`,
      },
    },
    {
      element: '[data-testid="mobile-nav-discuss"]',
      popover: {
        title: 'Discord',
        description: `Come join our friendly Japanese learning community!`,
      },
    },
    {
      element: '[data-testid="mobile-nav-profile"]',
      popover: {
        title: 'Account Info',
        description: `Account settings, including the ability to upgrade your account for more content.`,
      },
    },
    {
      element: '[data-testid="support-button"]',
      popover: {
        title: 'All done! Time to learn!',
        description: `Click this button to reopen this tutorial <img width="270" height="200" src="https://media.giphy.com/media/26u4lOMA8JKSnL9Uk/giphy.gif" />`,
      },
    },
  ],
  desktop: [
    {
      element: '[data-testid="app-nav-stories"]',
      popover: {
        title: 'Stories',
        description: `Learn Japanese contextually through stories in Japanese and English. Access grammar explanations, translations and line by line audio highlighting. <img width="744" height="762" src="/assets/support/stories.jpg" />`,
      },
    },
    {
      element: '[data-testid="app-nav-lessons"]',
      popover: {
        title: 'Lessons',
        description: `Master Japanese grammar and vocabulary with our flexible lessons .<img width="671" height="552" src="/assets/support/lessons.jpg" />`,
      },
    },
    {
      element: '[data-testid="app-nav-practice"]',
      popover: {
        title: 'Practice',
        description: `Hone your hiragana, katakana, conjugation, and time-telling skills here. <img width="672" height="540" src="/assets/support/practice.jpg" />`,
      },
    },
    {
      element: '[data-testid="app-nav-resources"]',
      popover: {
        title: 'Resources',
        description: `Access quick references for Japanese hiragana, katakana, and vocabulary. <img width="685" height="546" src="/assets/support/resources.jpg" />`,
      },
    },
    {
      element: '[data-testid="app-nav-discuss"]',
      popover: {
        title: 'Discord',
        description: `Come join our friendly Japanese learning community!`,
      },
    },
    {
      element: '[data-testid="app-nav-profile"]',
      popover: {
        title: 'Account Info',
        description: `Account settings, including the ability to upgrade your account for more content.`,
      },
    },
    {
      element: '[data-testid="support-button"]',
      popover: {
        title: 'All done! Time to learn!',
        description: `Click this button to reopen this tutorial <img width="270" height="200" src="https://media.giphy.com/media/26u4lOMA8JKSnL9Uk/giphy.gif" />`,
      },
    },
  ],
};

export const storiesSupport = {
  mobile: [
    {
      element: '[data-testid="story-pz-image"]',
      popover: {
        title: 'Free stories',
        description: `Stories are a great place to learn Japanese contextually. They are written in Japanese and English, and you can click on any word to see its definition. You can also click on the speaker icon to hear the audio.`,
      },
    },
    {
      element: '[data-testid="story-jq-image"]',
      popover: {
        title: 'Premium stories',
        description: `Our premium users have access to plenty of additional stories and content.`,
      },
    },
  ],
  desktop: [
    {
      element: '[data-testid="story-pz-image"]',
      popover: {
        title: 'Free stories',
        description: `Stories are a great place to learn Japanese contextually. They are written in Japanese and English, and you can click on any word to see its definition. You can also click on the speaker icon to hear the audio.`,
      },
    },
    {
      element: '[data-testid="story-jq-image"]',
      popover: {
        title: 'Premium stories',
        description: `Our premium users have access to plenty of additional stories and content.`,
      },
    },
  ],
};
