import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

import { getBillingPath, getLessonPath } from '@/utilities/paths';
import { parseContent } from '@/components/text/text';
import CheckCircle from '@/icons/fa/solid/check-circle';
import CheckCircleOutline from '@/icons/fa/light/check-circle';
import Star from '@/icons/fa/solid/star';
import StarOutline from '@/icons/fa/light/star';
import useIntersectionObserver from '@/hooks/use-intersection-observer';
import { useExpressLesson } from '@/utilities/api';

import cn from './lesson-card.module.css';

type LessonProps = {
  index: number;
  isLocked: boolean;
  isViewed: boolean;
  isBookmarked: boolean;
  name: string;
  interactionCount: number;
  sysId: string;
};

export function LessonCard(props: LessonProps) {
  const { interactionCount, sysId, name, isViewed, isBookmarked, isLocked } = props;
  const to = isLocked ? getBillingPath() : getLessonPath(sysId);
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});

  useExpressLesson(sysId, (entry?.isIntersecting ?? false) && !isLocked);

  return (
    <div
      ref={ref}
      className={classNames(cn.root, {
        [cn.isAvailable]: !isLocked,
        [cn.isBookmarked]: isBookmarked,
        [cn.isViewed]: isViewed,
      })}
    >
      <Link to={to} data-testid={`lesson-${sysId}`}>
        <div className={cn.content}>
          {/* <span className={cn.index}>{index}</span> */}
          <div className={cn.header}>
            <span className={cn.name} dangerouslySetInnerHTML={{ __html: parseContent(name) }} />
            {interactionCount > 0 ? (
              <span className={cn.quiz}>
                {interactionCount} quiz{interactionCount === 1 ? '' : 'zes'}
              </span>
            ) : null}
          </div>
          <div className={cn.badges}>
            {isLocked ? (
              <span className={cn.premium}>Premium</span>
            ) : (
              <>
                <div className={cn.star} title={isBookmarked ? 'You have bookmarked this lesson' : undefined}>
                  {isBookmarked ? <Star /> : <StarOutline />}
                </div>
                {isViewed ? (
                  <span title="You have read this lesson">
                    <CheckCircle className={cn.check} />
                  </span>
                ) : (
                  <span title="You have not viewed this lesson">
                    <CheckCircleOutline className={cn.check} />
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}
