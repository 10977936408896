import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import classNames from 'classnames';

import { Button } from '@/components/button/button';

import styles from './image-card.module.css';

interface ImageCardProps {
  buttonLabel: string;
  children: ReactNode;
  cover: boolean;
  imageUrl: string;
  isLocked?: boolean;
  testId?: string;
  to: string;
}

export function ImageCard(props: ImageCardProps) {
  const { cover, to, isLocked = false, imageUrl, buttonLabel, children, testId } = props;

  const content = (
    <>
      <div className={styles.imageCell}>
        {cover ? (
          <div
            data-testid={`${testId}-image`}
            className={styles.imageCover}
            style={{ backgroundImage: `url(${imageUrl}?v=1)` }}
          />
        ) : (
          <img data-testid={`${testId}-image`} className={styles.image} src={imageUrl} />
        )}
      </div>
      <div className={styles.contentCell}>{children}</div>
    </>
  );

  return isLocked ? (
    <span className={classNames(styles.root, styles.isLocked)} data-testid={testId}>
      {content}
    </span>
  ) : (
    <Link className={styles.root} to={to} data-testid={testId}>
      {content}
      <div className={styles.buttonCell}>
        <Button fullWidth>{buttonLabel}</Button>
      </div>
    </Link>
  );
}
