import { ReactNode } from 'react';

import style from './h3.module.css';

type H3Props = {
  children?: ReactNode;
};

export function H3(props: H3Props) {
  const { children } = props;
  return <h3 className={style.root}>{children}</h3>;
}
