import Table from '@/components/table/table';

import cn from './symbol-info.module.css';

export function SymbolInfo() {
  return (
    <div className={cn.root}>
      <Table
        items={[
          ['V', 'verb'],
          [
            'V plain',
            <>
              plain form of a verb <br />
              (e.g. のむ、のまない、のんだ、のまなかった）
            </>,
          ],
          [
            'V stem',
            <>
              The part without "ます" <br />
              (e.g. のみ、たべ、し、かき）
            </>,
          ],
          [
            'Vる',
            <>
              dictionrary form of a verb <br />
              (e.g. のむ、たべる、する）
            </>,
          ],
          [
            'Vない',
            <>
              ないform of a verb <br />
              (e.g. のまない、たべない、しない）
            </>,
          ],
          [
            'Vた',
            <>
              たform of a verb <br />
              (e.g. のんだ、たべた、した）
            </>,
          ],
          [
            'Vなかった',
            <>
              なかったform of a verb <br />
              (e.g. のまなかった、たべなかった）
            </>,
          ],
          [
            'Vて',
            <>
              てform of a verb
              <br /> (e.g. のんで、たべて、して）
            </>,
          ],
          [
            'Vよう',
            <>
              volitional form of a verb <br />
              (e.g. のもう、たべよう、しよう）
            </>,
          ],
          [
            'Vば',
            <>
              ばform of a verb <br />
              (e.g. のめば、たべれば、すれば）
            </>,
          ],
          ['', ''],
          [
            'いadj',
            <>
              dictionary form of いadjective <br />
              (e.g. さむい、おいしい）
            </>,
          ],
          [
            'いadj plain',
            <>
              plain form of いadjective <br />
              (e.g. さむい、さむくない、さむかった、さむくかった）
            </>,
          ],
          [
            'なadj',
            <>
              dictionary form of なadjective <br />
              (e.g. げんきな、しずかな）
            </>,
          ],
          [
            'なadj plain',
            <>
              plain form of なadjective <br />
              (e.g. げんきだ、げんきじゃない、げんきだった、げんきじゃなかった）
            </>,
          ],
          ['', ''],
          [
            'N',
            <>
              Noun <br />
              (e.g. がくせい、にほん、すし）
            </>,
          ],
          [
            'N plain',
            <>
              plain form of a noun <br />
              (e.g. がくせいだ、がくせいじゃない、がくせいだった、がくせいじゃなかった）
            </>,
          ],
          ['', ''],
          ['S', 'subject'],
          ['', ''],
          ['/', 'or'],
        ]}
      />
    </div>
  );
}
