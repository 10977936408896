import { useState } from 'react';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { Form } from '@/components/form/form';
import { FormError } from '@/components/form-error/form-error';
import { getWelcomePath } from '@/utilities/paths';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { Loader } from '@/components/loader/loader';
import { redirectWithFlash } from '@/utilities/flash';
import { register } from '@/utilities/api';
import { SimpleLink } from '@/components/simple-link/simple-link';
import { Sso } from '@/components/sso/Sso';
import { Stack } from '@/components/stack/stack';
import { Text } from '@/components/text/text';
import { UnauthenticatedLayout } from '@/layouts/unauthenticated-layout/unauthenticated-layout';
import { useRedirectAuthenticatedUser } from '@/hooks/use-redirect-authenticated-user';
import AtIcon from '@/icons/fa/solid/at';
import LockAltIcon from '@/icons/fa/solid/lock-alt';
import UserIcon from '@/icons/fa/solid/user';
import { Divvy } from '@/components/divvy/divvy';

export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  useRedirectAuthenticatedUser();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setEmailError(null);
    setPasswordError(null);
    setIsLoading(true);

    try {
      await register(name, email, password);
      redirectWithFlash(getWelcomePath(), 'Successfully registered!', 'success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setNameError(e?.response?.data?.errors?.name?.[0]);
      setEmailError(e?.response?.data?.errors?.email?.[0]);
      setPasswordError(e?.response?.data?.errors?.password?.[0]);
      setIsLoading(false);
    }
  };

  return (
    <UnauthenticatedLayout>
      <Form heading="Create your ImaWakatta account" onSubmit={onSubmit}>
        {isLoading && <Loader />}
        <Label text="Name">
          <Input
            icon={UserIcon}
            onChange={setName}
            placeholder="Eren Yeager"
            type="text"
            value={name}
            variant="large"
            testId="name"
          />
          {nameError && <FormError>{nameError}</FormError>}
        </Label>
        <Label text="Email address">
          <Input
            variant="large"
            icon={AtIcon}
            placeholder="name@example.com"
            onChange={setEmail}
            value={email}
            type="text"
            testId="email"
          />
          {emailError && <FormError>{emailError}</FormError>}
        </Label>
        <Label text="Password">
          <Input
            variant="large"
            icon={LockAltIcon}
            type="password"
            placeholder="Password"
            onChange={setPassword}
            value={password}
            testId="password"
          />
          {passwordError && <FormError>{passwordError}</FormError>}
        </Label>
        <Button type="submit" testId="submit">
          Create account
        </Button>
      </Form>
      <Stack gap="space-3">
        <Divvy text="OR" />
        <Sso />
        <ActionBar variant="center">
          <Text>
            Have an account? <SimpleLink to="/login">Sign in</SimpleLink>
          </Text>
        </ActionBar>
      </Stack>
    </UnauthenticatedLayout>
  );
}
