export const delim = '（　）'

export function splitSentence(s: string): string[] {
  const a = []
  let i = s.indexOf(delim)

  function push(s: string) {
    if (s.length > 0) {
      a.push(s)
    }
  }

  while (i >= 0) {
    push(s.slice(0, i)) // push on chunk
    a.push(delim) // push on the next found ()
    s = s.slice(i + delim.length) // trim down string to after the next found ()
    i = s.indexOf(delim)
  }

  push(s) // push any remaining text after the last ()

  return a
}
