import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { Modal } from '@/components/modal/modal';
import { destroyMe, logout } from '@/utilities/api';
import { FormError } from '@/components/form-error/form-error';
import { useAuthenticatedLayoutContext } from '@/layouts/authenticated-layout/authenticated-layout';

import styles from './account-close.module.css';

export function AccountClose() {
  const { user } = useAuthenticatedLayoutContext();

  const close = async () => {
    await destroyMe();
    await logout();
  };

  return (
    <Card>
      <div className={styles.content}>
        <p>We'd be sad to see you go, but if you wish to close your account, simply click below.</p>
        <p>Keep in mind that this action is irreversible, and all your data will be removed from our servers.</p>
        {user.subscriptionType === 'REVENUE_CAT' && (
          <FormError>
            You are subscribed via Apple. Make sure you cancel your subscription before deleting your account.
            <br />
            <a href="https://support.apple.com/en-us/HT202039">Details here</a>
          </FormError>
        )}
        <Modal
          heading="Do you want to close account?"
          onConfirm={close}
          trigger={<Button category="destructive">Close account</Button>}
          confirmLabel="Yes, close my account"
          confirmCategory="destructive"
        >
          Are you sure you want to close your account? This action is irreversible, and all your data will be removed
          from our servers.
        </Modal>
      </div>
    </Card>
  );
}
