import classNames from 'classnames';
import { ReactNode } from 'react';

import style from './toolbar.module.css';

type ToolbarProps = {
  children: ReactNode;
  variant?: 'center' | 'space-between' | 'end';
};

export function Toolbar(props: ToolbarProps) {
  const { children, variant } = props;

  return (
    <div className={style.root} data-testid="toolbar">
      <div
        className={classNames(style.wrapper, {
          [style.spaceBetween]: variant === 'space-between',
          [style.center]: variant === 'center',
          [style.end]: variant === 'end',
        })}
      >
        {children}
      </div>
    </div>
  );
}
