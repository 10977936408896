import { ReactNode } from 'react';
import { Browser } from '@capacitor/browser';

import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { LANDING_URL, isMobile, isProduction, privacyUrl, termsUrl, version } from '@/config';

import styles from './unauthenticated-layout.module.css';

type Props = {
  children: ReactNode;
};

export const UnauthenticatedLayout = (props: Props) => {
  const { children } = props;

  const openTerms = async () => {
    await Browser.open({ url: `${termsUrl}?chromeless=${isMobile}`, presentationStyle: 'popover' });
  };
  const openPrivacy = async () => {
    await Browser.open({ url: `${privacyUrl}?chromeless=${isMobile}`, presentationStyle: 'popover' });
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <main className={styles.content}>
          <a href={LANDING_URL} className={styles.brand}>
            <img alt="ImaWakatta" src="/android-chrome-384x384.png" className={styles.logo} />
          </a>
          <Card>{children}</Card>
          <ul className={styles.footer}>
            <li>
              <Button small category="link" onClick={openTerms}>
                Terms of Use
              </Button>
            </li>
            <li>
              <Button small category="link" onClick={openPrivacy}>
                Privacy Policy
              </Button>
            </li>
          </ul>
        </main>
      </div>

      <div className={styles.debug}>
        {version} {isProduction ? '' : <strong className={styles.debugWarning}>DEVELOPMENT BUILD</strong>}
      </div>
    </div>
  );
};
