import { hours, minutes } from '@/config';

export function generateAnswers(hour: number, minute: number) {
  const correctHour = hours.find((item) => item.number === hour);
  const correctMinute = minutes.find((item) => item.number === minute);

  if (!correctHour || !correctMinute) {
    return { preferredAnswers: [], acceptableAnswers: [] };
  }

  const preferredAnswers: Array<string> = [];
  correctHour.preferred.forEach((h) => correctMinute.preferred.forEach((m) => preferredAnswers.push(`${h}${m}`)));

  // Create a x-matrix of acceptable. This may be:
  // preferred  h + acceptable m
  // acceptable h + preferred  m
  // acceptable h + acceptable m
  const acceptableAnswers: Array<string> = [];
  const pushAcceptable = (hour: string, minute: string) => acceptableAnswers.push(`${hour}${minute}`);
  correctHour.preferred.forEach((h) => correctMinute.acceptable.forEach((m) => pushAcceptable(h, m)));
  correctHour.acceptable.forEach((h) => correctMinute.preferred.forEach((m) => pushAcceptable(h, m)));
  correctHour.acceptable.forEach((h) => correctMinute.acceptable.forEach((m) => pushAcceptable(h, m)));

  return { preferredAnswers, acceptableAnswers };
}
