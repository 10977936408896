import { getCorrectMessage, getIncorrectMessage } from '@/content';
import { Button } from '@/components/button/button';
import { Feedback } from '@/components/feedback/feedback';

import { ActionBar } from '../action-bar/action-bar';

type FooterProps = {
  active: boolean;
  correctTexts: string[];
  isComplete: boolean;
  isSuccessful: boolean;
  markAsComplete: () => void;
  selected: string[];
};

export function Footer(props: FooterProps) {
  const { active, isSuccessful, isComplete, selected, markAsComplete, correctTexts } = props;

  if (isComplete) {
    const correctText = getCorrectMessage();
    const incorrectText = getIncorrectMessage(correctTexts);

    return (
      <Feedback type={isSuccessful ? 'correct' : 'incorrect'} message={isSuccessful ? correctText : incorrectText} />
    );
  }

  const checkEnabled = !isComplete && selected.length > 0 && active;
  return (
    <ActionBar variant="center">
      <Button enabled={checkEnabled} onClick={markAsComplete} testId="multiple-choice-check">
        Check
      </Button>
    </ActionBar>
  );
}
