import { Card } from '@/components/card/card';
import { HeroCard } from '@/components/hero-card/hero-card';
import { ImageCard } from '@/components/image-card/image-card';

import styles from './resources.module.css';

const resources = [
  {
    image: '/assets/emoji/japanese-service-charge-button.png',
    label: 'Hiragana/Katakana',
    url: '/resources/kana',
  },
  {
    image: '/assets/emoji/books.png',
    label: 'Vocabulary',
    url: '/resources/vocabulary',
  },
];

export default function Resources() {
  return (
    <HeroCard size="wide" title="Resources">
      <div className={styles.root}>
        <Card>
          <div className={styles.content}>
            <p>Your central place for reference material.</p>
            <p>
              If you need to quickly look up some vocabulary or see full tables of hiragana and katakana, checkout the
              below resources.
            </p>
          </div>
        </Card>

        <div className={styles.items}>
          {resources.map((item, i) => (
            <ImageCard cover={false} to={item.url} imageUrl={item.image} buttonLabel="View resource" key={i}>
              <strong className={styles.title}>{item.label}</strong>
            </ImageCard>
          ))}
        </div>
      </div>
    </HeroCard>
  );
}
