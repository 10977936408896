import { useState } from 'react';

import { ActionBar } from '@/components/action-bar/action-bar';
import { Button } from '@/components/button/button';
import { decodeBase64 } from '@/utilities/paths';
import { flash } from '@/utilities/flash';
import { forgot, reset } from '@/utilities/api';
import { Form } from '@/components/form/form';
import { FormError } from '@/components/form-error/form-error';
import { Input } from '@/components/input/input';
import { Label } from '@/components/label/label';
import { Loader } from '@/components/loader/loader';
import { SimpleLink } from '@/components/simple-link/simple-link';
import { Stack } from '@/components/stack/stack';
import { UnauthenticatedLayout } from '@/layouts/unauthenticated-layout/unauthenticated-layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRedirectAuthenticatedUser } from '@/hooks/use-redirect-authenticated-user';

function decodeQueryToken(queryToken: string): { email: string; token: string } {
  if (queryToken === '') {
    return { email: '', token: '' };
  }

  return JSON.parse(decodeBase64(queryToken));
}

export default function Reset() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryToken = searchParams.get('token') ?? '';
  const { email: initialEmail, token } = decodeQueryToken(queryToken);
  const isForgetStep = token === '';
  const isResetStep = !isForgetStep;

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  useRedirectAuthenticatedUser();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setEmailError(null);
    setPasswordError(null);
    setIsLoading(true);

    try {
      if (isForgetStep) {
        await forgot(email);
        flash('We have sent you an email. You can now close this window.', 'success');
        setEmail('');
        setIsLoading(false);
      } else {
        await reset(email, password, token);
        flash('Password successfully updated. Please sign in.', 'success');
        navigate('/login');
      }
    } catch (e: any) {
      setEmailError(e?.response?.data?.errors?.email?.[0]);
      setPasswordError(e?.response?.data?.errors?.password?.[0]);
      setIsLoading(false);
    }
  };

  return (
    <UnauthenticatedLayout>
      <Form heading="Forgot password" onSubmit={onSubmit}>
        {isLoading && <Loader />}
        <Label text="Email address">
          <Input
            variant="large"
            placeholder="name@example.com"
            onChange={setEmail}
            value={email}
            type="text"
            testId="email"
          />
          {emailError && <FormError>{emailError}</FormError>}
        </Label>
        {isResetStep && (
          <>
            <input type="hidden" value={token} />
            <Label text="Password">
              <Input
                variant="large"
                type="password"
                placeholder="Password"
                onChange={setPassword}
                value={password}
                testId="password"
              />
              {passwordError && <FormError>{passwordError}</FormError>}
            </Label>
          </>
        )}
        <Stack gap="space-3">
          <Button type="submit" testId="submit">
            {isForgetStep ? 'Send email' : 'Update password'}
          </Button>
          <ActionBar variant="center">
            <SimpleLink to="/login">Back to sign in</SimpleLink>
          </ActionBar>
        </Stack>
      </Form>
    </UnauthenticatedLayout>
  );
}
