import { API_URL, isMobile } from '@/config';
import { appleLogin, googleLogin } from '@/utilities/api';
import { Button } from '@/components/button/button';
import { getWelcomePath } from '@/utilities/paths';
import { redirectWithFlash } from '@/utilities/flash';
import Apple from '@/icons/fa/brands/apple';
import Google from '@/icons/fa/brands/google';

export function Sso() {
  const appleSignIn = async (e: Event) => {
    e.preventDefault();
    try {
      await appleLogin();
      redirectWithFlash(getWelcomePath(), 'Welcome back!', 'success');
    } catch (e) {
      console.warn('Cancelled apple sign in', e);
    }
  };

  const googleSignUp = async (e: Event) => {
    e.preventDefault();
    try {
      await googleLogin();
      redirectWithFlash(getWelcomePath(), 'Welcome back!', 'success');
    } catch (e) {
      console.warn('Cancelled google sign in', e);
    }
  };

  return isMobile ? (
    <>
      <Button category="secondary" onClick={googleSignUp} icon={Google}>
        Sign in with Google
      </Button>
      <Button category="secondary" onClick={appleSignIn} icon={Apple}>
        Sign in with Apple
      </Button>
    </>
  ) : (
    <>
      <Button category="secondary" asAnchor href={`${API_URL}/login/google/web`} icon={Google}>
        Sign in with Google
      </Button>
      <Button category="secondary" asAnchor href={`${API_URL}/login/apple/web`} icon={Apple}>
        Sign in with Apple
      </Button>
    </>
  );
}
