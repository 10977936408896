type ICollection = Array<number> | { [s: string]: number };

// Wraps the given value as an array. If the given value is already an array it will not be changed
export const arrayWrap = (val: any) => {
  return Array.isArray(val) ? val : [val];
};

// min and max included
export const randBetween = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// similar to _.isNil - note it is intentionally == to account for both null and undefined
export const isNil = (val: any): boolean => {
  return val == null;
};
