import { useEffect, useState } from 'react';
import { shuffle } from 'lodash';

import { Audio } from '@/components/audio/audio';
import { Info } from '@/components/info/info';
import { MultipleChoice as MultipleChoiceType } from '@/utilities/api';
import { parseContent, Text } from '@/components/text/text';
import { Stack } from '@/components/stack/stack';
import { toggle } from '@/utilities/collection';
import ImageLoader from '@/components/image-loader/image-loader';

import { Answer } from './answer';
import { Footer } from './footer';
import style from './multiple-choice.module.scss';

type Answer = {
  id: string;
  text: string;
  subtext: string;
  correct: boolean;
};

type MultipleChoiceProps = {
  onComplete: (type: string, sysId: string, score: number, meta: any) => void;
  sysId: string;
  active?: boolean;
  config: MultipleChoiceType['config'];
};

export function MultipleChoice(props: MultipleChoiceProps) {
  const { active = true, sysId, config, onComplete } = props;
  const { answers, question, image, random = false, imageConfig } = config;

  const isSelected = (id: string) => selected.includes(id);

  const [answerOrder, setAnswerOrder] = useState<Array<string>>([]);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const isCorrect = answers.every((v) => (v.correct && isSelected(v.id)) || (!v.correct && !isSelected(v.id)));
  const isMultiple = answers.filter((v) => v.correct).length > 1;
  const correctTexts = answers.filter((v) => v.correct).map((v) => v.text);

  useEffect(() => {
    const answerIds = answers.map((answer) => answer.id);
    setAnswerOrder(random ? shuffle(answerIds) : answerIds);
  }, [answers, random, setAnswerOrder]);

  const answerById = (id: string) => answers.find((answer) => answer.id === id);

  const handleAnswer = (id: string) => {
    if (isMultiple) {
      setSelected(toggle(selected, id));
    } else {
      setSelected(isSelected(id) ? [] : [id]);
    }
  };

  const markAsComplete = () => {
    setIsComplete(true);
    onComplete('multiple-choice', sysId, isCorrect ? 5 : 1, { selected });
  };

  return (
    <div data-testid="multiple-choice" className={style.root}>
      <Stack gap="space-3">
        {image && <ImageLoader size={imageConfig.backgroundSize} src={image} />}
        {!isComplete && <Info>{`Click the correct answer${isMultiple ? '(s)' : ''} below`}</Info>}
        <Text element="h2" className={style.heading}>
          {question}
        </Text>
        <div>
          {answerOrder.map((id) => {
            const answer = answerById(id);

            if (!answer) {
              return null;
            }

            return (
              <Answer
                active={active}
                key={id}
                isComplete={isComplete}
                isSelected={isSelected}
                handleAnswer={handleAnswer}
                correct={answer.correct}
                id={answer.id}
                subtext={<span dangerouslySetInnerHTML={{ __html: parseContent(answer.subtext ?? '') }} />}
                text={<span dangerouslySetInnerHTML={{ __html: parseContent(answer.text ?? '') }} />}
              />
            );
          })}
        </div>
        <Footer
          active={active}
          isSuccessful={isCorrect}
          isComplete={isComplete}
          markAsComplete={markAsComplete}
          selected={selected}
          correctTexts={correctTexts}
        />
      </Stack>
      {config.audio && (
        <div className={style.audio}>
          <Audio disabled={!isComplete} url={config.audio} />
        </div>
      )}
    </div>
  );
}
