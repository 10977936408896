import { Card } from '@/components/card/card';
import { H2 } from '@/components/h2/h2';
import { Label } from '@/components/label/label';
import { Stack } from '@/components/stack/stack';
import { Tags } from '@/components/tags/tags';
import { Text } from '@/components/text/text';
import { type Vocabulary } from '@/apps/app/features/vocabulary/vocabulary';

export function VocabularyCard(props: Vocabulary) {
  const { kanji, kana, type, tags, english } = props;
  const hasKanji = kanji !== '';

  return (
    <Card>
      <Stack>
        <H2>{hasKanji ? kanji : kana}</H2>
        <Tags
          idKey="label"
          labelKey="label"
          items={[type ? { label: type, value: type } : { label: 'No classification' }, ...tags]}
        />
        {hasKanji && (
          <Label text="Kana">
            <Text>{kana}</Text>
          </Label>
        )}
        <Label text="English">
          <Text>{english}</Text>
        </Label>
      </Stack>
    </Card>
  );
}
