import classNames from 'classnames';
import { ReactNode } from 'react';

import cn from './stack.module.css';

type StackProps = {
  children: ReactNode;
  variant?: 'rowToColumn' | 'row' | 'column';
  gap?: 'space-1' | 'space-2' | 'space-3' | 'space-4' | 'space-5' | 'space-6' | 'space-7' | 'space-8' | 'space-9';
};

export function Stack(props: StackProps) {
  const { children, variant = 'column', gap = 'space-3' } = props;
  return <div className={classNames(cn.root, cn[variant], cn[gap])}>{children}</div>;
}
