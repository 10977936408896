import style from './progress-bar.module.css';

type ProgressBarProps = {
  current: number;
  total: number;
};

export function ProgressBar(props: ProgressBarProps) {
  const { current, total } = props;

  return (
    <div className={style.root}>
      <div className={style.barWrapper}>
        <div className={style.bar} style={{ width: `${(current / total) * 100}%` }} />
      </div>
      <div className={style.text}>
        {current} / {total}
      </div>
    </div>
  );
}
