import classNames from 'classnames';
import { Component, CSSProperties, ReactNode } from 'react';

import style from './table.module.css';

interface IProps {
  className?: string;
  headers?: Array<ReactNode>;
  items: Array<Array<ReactNode>>;
  layout?: 'vertical' | 'left-headers';
  styles?: Array<CSSProperties>;
  isVerticalAlign?: boolean;
}

export default class Table extends Component<IProps> {
  static defaultProps = {
    layout: 'vertical',
  };

  render() {
    const { className, headers, items, layout, styles = [], isVerticalAlign = false } = this.props;

    return (
      <table className={classNames(style.root, className, { [style.isVerticalAlign]: isVerticalAlign })}>
        {headers && (
          <thead className={style.thead}>
            <tr className={style.row}>
              {headers.map((header, i) => (
                <th key={i} className={style.th} style={styles[i]}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className={style.tbody}>
          {items.map((item, i) => (
            <tr key={i} className={style.row}>
              {item.map((item, i) => {
                return layout === 'left-headers' && i === 0 ? (
                  <th key={i} className={style.th}>
                    {item}
                  </th>
                ) : (
                  <td key={i} className={style.td}>
                    {item}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
