import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ChevronLeft from '@/icons/fa/solid/chevron-left';

import styles from './breadcrumb.module.css';

type BreadcrumbProps = {
  items: Array<{ href?: string; label: string; onClick?: () => void } | null>;
};

export function Breadcrumb(props: BreadcrumbProps) {
  const { items } = props;
  return (
    <div className={styles.root}>
      {items.map((item, i) => {
        if (!item) return null;

        return (
          <Fragment key={i}>
            {item.href ? (
              <Link key={i} className={styles.button} to={item.href} data-testid={`breadcrumb-${i}`}>
                <ChevronLeft className={styles.icon} />
                {item.label}
              </Link>
            ) : (
              <span
                onClick={item.onClick}
                className={classNames(styles.button, { [styles.inactive]: !item.onClick })}
                data-testid={`breadcrumb-${i}`}
              >
                <ChevronLeft className={styles.icon} />
                {item.label}
              </span>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
