import { arrayWrap, isNil } from './util';

// @ts-ignore
export const money = (value) => `$${(value / 100).toFixed(2)}`;

export const msAsMins = (ms: number) => (ms / 60000).toFixed(2);

export const msAsTime = (ms: number) => {
  ms = 1000 * Math.round(ms / 1000);
  const d = new Date(ms);

  let seconds = d.getUTCSeconds().toString();
  seconds = seconds.length === 1 ? `0${seconds}` : seconds;

  let minutes = d.getUTCMinutes().toString();

  return `${minutes}min${minutes === '1' ? '' : 's'} ${seconds}sec${seconds === '01' ? '' : 's'}`;
};

/**
 * Joins an array of strings into a sentence like "a, b and c"
 * @param items
 * @param delim
 * @param lastDelim
 * @param emptyMessage
 *
 */
// prettier-ignore
export function joinDelim(items: string|string[], delim = ', ', lastDelim = ' and ', emptyMessage = '') {
  const filteredItems = arrayWrap(items).filter(v => !isNil(v))

  if (filteredItems.length === 0) {
    return emptyMessage
  } else if (filteredItems.length === 1) {
    return filteredItems[0]
  } else {
    return filteredItems
      .map((v, i) => {
        if(i === filteredItems.length - 2) {
          return v + lastDelim
        } else if(i === filteredItems.length - 1) {
          return v
        } else {
          return v + delim
        }
      })
      .join('')
  }
}
