export function replace<T>(array: Array<T>, item: T, replacement: T): Array<T> {
  const index = array.indexOf(item)
  if (index === -1) {
    return array
  } else {
    return [...array.slice(0, index), replacement, ...array.slice(index + 1)]
  }
}

export function remove<T>(array: Array<T>, item: T): Array<T> {
  const index = array.indexOf(item)
  if (index === -1) {
    return array
  } else {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }
}

export function toggle<T>(array: Array<T>, item: T): Array<T> {
  const index = array.indexOf(item)
  if (index === -1) {
    return [...array, item]
  } else {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }
}
