import styles from './divvy.module.scss';

type DivvyProps = {
  text?: string;
};

export function Divvy(props: DivvyProps) {
  const { text } = props;
  return (
    <div className={styles.root}>
      {text ? <div className={styles.text}>{text}</div> : <div className={styles.line} />}
    </div>
  );
}
