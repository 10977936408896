import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { getLessonsPath, getPracticePath, getResourcesPath, getStoriesPath, getWelcomePath } from '@/utilities/paths';

import ClipboardCheck from '@/icons/fa/light/clipboard-check';
import Book from '@/icons/fa/light/book';
import ChalkboardTeacher from '@/icons/fa/light/chalkboard-teacher';
import LampDesk from '@/icons/fa/light/lamp-desk';
import PenAlt from '@/icons/fa/light/pen-alt';

import styles from './mobile-bottom-nav.module.css';

const matchers: { [key: string]: (pathname: string) => boolean } = {
  dashboard: (pathname: string) => pathname === '/',
  stories: (pathname: string) => pathname.startsWith('/stories'),
  lessons: (pathname: string) => pathname.startsWith('/lessons'),
  practice: (pathname: string) => pathname.startsWith('/practice'),
  resources: (pathname: string) => pathname.startsWith('/resources'),
  none: () => false,
};

export function MobileBottomNav() {
  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <Link
          className={classNames(styles.link, { [styles.isActive]: matchers['dashboard'](pathname) })}
          to={getWelcomePath()}
        >
          <ClipboardCheck />
          Dashboard
        </Link>
      </div>
      <div className={styles.item}>
        <Link
          className={classNames(styles.link, { [styles.isActive]: matchers['stories'](pathname) })}
          to={getStoriesPath()}
          data-testid="mobile-nav-stories"
        >
          <Book />
          Stories
        </Link>
      </div>
      <div className={styles.item}>
        <Link
          className={classNames(styles.link, { [styles.isActive]: matchers['lessons'](pathname) })}
          to={getLessonsPath()}
          data-testid="mobile-nav-lessons"
        >
          <ChalkboardTeacher />
          Lessons
        </Link>
      </div>
      <div className={styles.item}>
        <Link
          className={classNames(styles.link, { [styles.isActive]: matchers['practice'](pathname) })}
          to={getPracticePath()}
          data-testid="mobile-nav-practice"
        >
          <LampDesk />
          Practice
        </Link>
      </div>
      <div className={styles.item}>
        <Link
          className={classNames(styles.link, { [styles.isActive]: matchers['resources'](pathname) })}
          to={getResourcesPath()}
          data-testid="mobile-nav-resources"
        >
          <PenAlt />
          Resources
        </Link>
      </div>
    </div>
  );
}
