import { Card } from '@/components/card/card';
import { HeroCard } from '@/components/hero-card/hero-card';
import { data, KanaTable, TableKey } from '@/components/kana-table/kana-table';

import styles from './kana-tables.module.css';

export default function KanaTables() {
  return (
    <HeroCard title="Kana" size="medium" breadcrumbs={[{ label: 'Resources', href: '/resources' }]}>
      <div className={styles.root}>
        {Object.keys(data).map((type, i) => (
          <div className={styles.item} key={i}>
            <Card>
              <KanaTable category={type as TableKey} />
            </Card>
          </div>
        ))}
      </div>
    </HeroCard>
  );
}
