import { ReactNode } from 'react';

import style from './highlight.module.css';

type HighlightProps = {
  children: ReactNode;
};

export function Highlight(props: HighlightProps) {
  const { children } = props;
  return <span className={style.root}>{children}</span>;
}
