import { ReactNode } from 'react';

import style from './key.module.css';

type KeyProps = {
  children: ReactNode;
};

export function Key(props: KeyProps) {
  const { children } = props;
  return <span className={style.root}>{children}</span>;
}
