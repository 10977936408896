import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { HeroCard } from '@/components/hero-card/hero-card';
import { get } from '@/utilities/api';
import { Loader } from '@/components/loader/loader';

import { KanaQuiz } from './kana-quiz/kana-quiz';
import { Setup } from './setup/setup';
import styles from './kana-practice.module.css';

type Config = {
  sysId: string;
  type: string;
};

export default function KanaPractice() {
  const [setup, setSetup] = useState<boolean>(true);
  const [hiraganaMode, setHiraganaMode] = useState<boolean>(true);
  const [japaneseMode, setJapaneseMode] = useState<boolean>(true);
  const [difficulty, setDifficulty] = useState<number>(4);
  const { isLoading, isSuccess, data } = useQuery(['kana-practice'], () => get<Config>('interaction/config/kana'));

  return (
    <HeroCard
      title="Kana practice"
      breadcrumbs={[
        setup ? { label: 'Practice', href: '/practice' } : { label: 'Setup', onClick: () => setSetup(true) },
      ]}
      size="medium"
    >
      <div className={styles.root}>
        {isLoading && <Loader isGlobal />}
        {isSuccess &&
          (setup ? (
            <Setup
              japaneseMode={japaneseMode}
              hiraganaMode={hiraganaMode}
              difficulty={difficulty}
              setDifficulty={setDifficulty}
              setHiraganaMode={setHiraganaMode}
              setJapaneseMode={setJapaneseMode}
              begin={() => setSetup(false)}
            />
          ) : (
            // @ts-ignore
            <KanaQuiz config={data} japaneseMode={japaneseMode} hiraganaMode={hiraganaMode} answerCount={difficulty} />
          ))}
      </div>
    </HeroCard>
  );
}
