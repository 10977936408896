import classNames from 'classnames';

import { Button } from '@/components/button/button';
import { twitchUrl } from '@/config';
import TwitchIcon from '@/icons/fa/brands/twitch';

import { Panel } from '../panel/panel';
import styles from '../panel/panel.module.css';

export function Twitch() {
  return (
    <Panel
      heading="Thank you for being a Twitch subscriber!"
      footer="You can manage your subscription on Twitch"
      icon={<TwitchIcon className={classNames(styles.headingIcon, styles.twitch)} />}
    >
      <Button category="twitch" fullWidth href={twitchUrl} asAnchor target="_blank">
        Visit Twitch
      </Button>
    </Panel>
  );
}
