import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';

import '@/styles/normalize.css';
import '@/styles/globals.css';

import { AuthenticatedLayout } from '@/layouts/authenticated-layout/authenticated-layout';
import { GlobalError } from '@/components/global-error/global-error';
import { isProduction, sentryDsn } from '@/config';

// App
import Account from '@/apps/app/features/account/account';
import ConjugationPractice from '@/apps/app/features/conjugation-practice/conjugation-practice';
import KanaPractice from '@/apps/app/features/kana-practice/kana-practice';
import KanaTables from '@/apps/app/features/kana-tables/kana-tables';
import Lesson from '@/apps/app/features/lesson/lesson';
import Lessons from '@/apps/app/features/lessons/lessons';
import Login from '@/apps/app/features/auth/login/login';
import Practice from '@/apps/app/features/practice/practice';
import Resources from '@/apps/app/features/resources/resources';
import Register from '@/apps/app/features/auth/register/register';
import Reset from '@/apps/app/features/auth/reset/reset';
import Stories from '@/apps/app/features/stories/stories/stories';
import Story from '@/apps/app/features/stories/story/story';
import TimePractice from '@/apps/app/features/time-practice/time-practice';
import Vocabulary from '@/apps/app/features/vocabulary/vocabulary';
import Welcome from '@/apps/app/features/welcome/welcome';
import Page404 from './features/page404/page404';

// Sync + non-react-query async --> Sentry
Sentry.init({
  dsn: sentryDsn,
  tracesSampleRate: 0.5,
  enabled: isProduction,
  // This was causing a lot of noise sentry: https://github.com/getsentry/sentry-javascript/issues/2292
  ignoreErrors: ['Non-Error exception captured'],
  beforeSend: (event) => {
    console.log('💣 global error', { event });
    // @ts-ignore
    document.getElementById('global-error').style.display = 'flex';
    return event;
  },
});

const queryClient = new QueryClient({
  logger: {
    log: (message) => {
      Sentry.captureMessage(message);
    },
    warn: (message) => {
      Sentry.captureMessage(message);
    },
    error: (error) => {
      Sentry.captureException(error);
    },
  },
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 6, // 24 hours
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/* LOGIN */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password/reset" element={<Reset />} />

          {/* WITHOUT TOOLBAR */}
          <Route path="/" element={<AuthenticatedLayout />}>
            {/* LEARN */}
            <Route index element={<Welcome />} />
            <Route path="/lessons" element={<Lessons />} />
            <Route path="/lessons/:lessonId" element={<Lesson />} />

            {/* PRACTICE */}
            <Route path="/practice" element={<Practice />} />
            <Route path="/practice/conjugation" element={<ConjugationPractice />} />
            <Route path="/practice/kana" element={<KanaPractice />} />
            <Route path="/practice/time" element={<TimePractice />} />

            {/* STORIES */}
            <Route path="/stories" element={<Stories />} />

            {/* RESOURCES */}
            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/kana" element={<KanaTables />} />
            <Route path="/resources/vocabulary" element={<Vocabulary />} />

            {/* ACCOUNT */}
            <Route path="/account/*" element={<Account />} />

            {/* 404 */}
            <Route path="*" element={<Page404 />} />
          </Route>

          {/* WITH TOOLBAR */}
          <Route path="/" element={<AuthenticatedLayout withToolbar />}>
            {/* STORIES */}
            <Route path="/stories/:storyId" element={<Story />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <GlobalError />
      <Toaster position="top-center" />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

createRoot(document.getElementById('app') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);

function handleUncaught(e: ErrorEvent | PromiseRejectionEvent) {
  if ('message' in e && e.message.includes('error loading dynamically imported module')) {
    console.log('new version available, reload!');
    window.location.reload();
  }
}

window.addEventListener('error', handleUncaught);
window.addEventListener('unhandledrejection', handleUncaught);
