import classNames from 'classnames';
import { ReactNode } from 'react';

import style from './action-bar.module.css';

type ActionBarProps =
  | {
      children: ReactNode;
    } & (
      | {
          direction: 'column';
          variant?: undefined;
        }
      | {
          direction?: 'row';
          variant?: 'center' | 'space-between' | 'end';
        }
    );

export function ActionBar(props: ActionBarProps) {
  const { children, variant = 'end', direction = 'row' } = props;

  return (
    <div
      className={classNames(style.root, {
        [style.row]: direction === 'row',
        [style.column]: direction === 'column',
        [style.center]: variant === 'center',
        [style.spaceBetween]: variant === 'space-between',
        [style.end]: variant === 'end',
      })}
    >
      {children}
    </div>
  );
}
