import { delim } from './split-sentence';

export function constructAnswer(questionParts: string[], answers: string[]) {
  let delimCount = 0;

  return questionParts
    .map((part) => {
      let chunk;
      if (part === delim) {
        chunk = answers[delimCount];
        delimCount++;
      } else {
        chunk = part;
      }
      return chunk;
    })
    .join(' ');
}
