import { english, hiragana, katakana } from '@/config';

export function lookup(index: number, hiraganaMode: boolean, japaneseMode: boolean) {
  let value;
  if (japaneseMode && hiraganaMode) {
    value = hiragana[index];
  } else if (japaneseMode && !hiraganaMode) {
    value = katakana[index];
  } else {
    value = english[index];
  }
  return value;
}
