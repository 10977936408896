import { Card } from '@/components/card/card';
import { Chip } from '@/components/chip/chip';
import { getStoryPath } from '@/utilities/paths';
import { HeroCard } from '@/components/hero-card/hero-card';
import { ImageCard } from '@/components/image-card/image-card';
import { Loader } from '@/components/loader/loader';
import { SupportButton } from '@/components/support-button/support-button';
import { Tags } from '@/components/tags/tags';
import { Text } from '@/components/text/text';
import { type StoriesStory } from '@/utilities/api';
import { useDriver } from '@/hooks/use-driver';
import { useStories, useStory } from '@/utilities/api';
import Check from '@/icons/fa/light/check';
import Tag from '@/icons/fa/solid/tag';

import styles from './stories.module.css';
import { useRef } from 'react';
import useIntersectionObserver from '@/hooks/use-intersection-observer';

function Story(props: StoriesStory) {
  const { sysId, isLocked, image, title, englishTitle, isRead, tags } = props;
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});

  useStory(sysId, (entry?.isIntersecting ?? false) && !isLocked);

  return (
    <ImageCard
      cover={true}
      to={getStoryPath(sysId)}
      isLocked={isLocked}
      imageUrl={image}
      buttonLabel="Read story"
      testId={`story-${sysId}`}
    >
      <div ref={ref}>
        <Text className={styles.title}>{title}</Text>
        <p className={styles.byline}>{englishTitle}</p>
        <div className={styles.tags}>
          {isLocked && <Chip type="disabled">Premium only</Chip>}
          <Chip type={isRead && !isLocked ? 'success' : 'disabled'} icon={isRead ? Check : undefined}>
            {isRead ? 'Read' : 'Unread'}
          </Chip>
          <Tags idKey="value" icon={Tag} items={tags} />
        </div>
      </div>
    </ImageCard>
  );
}

export default function Stories() {
  const { isLoading, isSuccess, data } = useStories();
  const isReady = !isLoading && isSuccess && data;
  const driver = useDriver('stories');

  return (
    <HeroCard size="wide" title="Stories">
      {!isReady ? (
        <Loader isGlobal />
      ) : (
        <div className={styles.root}>
          <Card>
            <p className={styles.intro}>
              Reading real Japanese, with context, is a <strong>fantastic</strong> way to learn new vocabulary and
              grammar!
            </p>
          </Card>

          <div className={styles.items}>
            {data.map((story, i) => (
              <Story key={i} {...story} />
            ))}
          </div>
        </div>
      )}
      <SupportButton driver={driver} />
    </HeroCard>
  );
}
