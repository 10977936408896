import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { IconProps } from '@/icons/icon-props';
import { Text } from '@/components/text/text';

import style from './button.module.css';

const noop = () => {};

interface IProps {
  category?: 'primary' | 'secondary' | 'link' | 'whiteLink' | 'bordered' | 'destructive' | 'twitch';
  children?: ReactNode;
  className?: string;
  enabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: (props: IconProps) => JSX.Element;
  postIcon?: (props: IconProps) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: any;
  large?: boolean;
  asAnchor?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
  small?: boolean;
  tabIndex?: number;
  testId?: string;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
}

function ButtonBase(props: IProps) {
  const {
    category = 'primary',
    children,
    className,
    enabled = true,
    fullWidth = false,
    asAnchor = false,
    href,
    icon: Icon = null,
    postIcon: PostIcon = null,
    innerRef,
    large = false,
    onClick,
    small = false,
    tabIndex,
    testId,
    target,
    type,
    title,
  } = props;

  const combinedClassNames = classNames(
    style.root,
    style[category],
    {
      [style.disabled]: !enabled,
      [style.fullWidth]: fullWidth,
      [style.small]: small,
      [style.large]: large,
    },
    className
  );

  if (href && asAnchor) {
    return (
      <a
        className={combinedClassNames}
        ref={innerRef}
        tabIndex={tabIndex}
        href={href}
        data-testid={testId}
        target={target}
      >
        {Icon && <Icon className={style.icon} />}
        <Text element="span" noStyle>
          {children}
        </Text>
        {PostIcon && <PostIcon className={style.postIcon} />}
      </a>
    );
  } else if (href) {
    return (
      <Link to={href} className={combinedClassNames} ref={innerRef} tabIndex={tabIndex} data-testid={testId}>
        {Icon && <Icon className={style.icon} />}
        <Text element="span" noStyle>
          {children}
        </Text>
        {PostIcon && <PostIcon className={style.postIcon} />}
      </Link>
    );
  } else {
    return (
      <button
        className={combinedClassNames}
        onClick={enabled ? onClick : noop}
        ref={innerRef}
        tabIndex={tabIndex}
        type={type}
        title={title}
        data-testid={testId}
      >
        {Icon && (
          <span className={style.icon}>
            <Icon />
          </span>
        )}
        {children}
        {PostIcon && (
          <span className={style.postIcon}>
            <PostIcon />
          </span>
        )}
      </button>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Button = forwardRef<any, IProps>(function Button(props, ref) {
  return <ButtonBase innerRef={ref} {...props} />;
});
